<template>
    <div id="tourist_visitors" class="tourist_visitors">
        <taskCommon title="旅游游客"
                    :currentTree="currentTree"
                    :list="list"
                    :snSearch="snSearch"
                    :showTree="false"
                    @handleChange="handleChange"
        >
            <slot>
                <div class="sn_tablebody">
                    <sn-load v-show="commonData.loading"></sn-load>
                    <sn-table v-show="!commonData.loading" ref="tableBox" :snTable.sync="commonData.snTable" :boxHeight.sync="boxHeight" :commonMethods="commonMethods" @handleChange="handleChange"></sn-table>
                    <sn-page v-show="!commonData.loading" :snPage.sync="commonData.snPage" @handleChange="handleChange"></sn-page>
                </div>
                <taskManage ref="taskManage" :tableShow="false" :touristData="commonData"></taskManage>
                <Dialog ref="dialog" :dialogData.sync="dialogData" :zoneId="currentTree" @saveSuccess="saveSuccess"></Dialog>
            </slot>
        </taskCommon>
    </div>
</template>
<script>
import mixins from '../mixins';
import { commonData } from './tourist_visitors_table';
import { commonMethods } from '../table-common-methods';
import taskManage from '../task_manage';
import Dialog from './tourist_visitors_dialog';
import { mapActions } from 'vuex';
export default {
    name: 'tourist_visitors',
    components: {
        taskManage,
        Dialog
    },
    mixins: [mixins],
    data() {
        return {
            commonData,
            commonMethods,
            currentId: 1103,
            boxHeight: 500,
            showNewButton: true
        };
    },
    created() {

    },
    mounted() {
        this.autoResize();
        this.$refs.taskManage.initSearch();
    },
    methods: {
        ...mapActions([
            'getRwglList'
        ]),
        changeList(val) {
            let _this = this;
            _this.currentTree = val[0];
            // let patrolName = val[1];
            _this.dialogData.title = `游客量-监测任务配置`;
            _this.$refs.dialog.getDetail(_this.currentTree);
            _this.dialogData.dialog = true;
        },
        creatMonitor() {
            let _this = this;
            _this.dialogData.title = '游客量监测配置' + (Math.random() * 100).toFixed(0); // TODO 删除随机数
            _this.dialogData.dialog = true;
        },
        handleSelfChange(type, value) {
            this.$refs.taskManage.handleChange(type, value);
        },
        saveSuccess() {
            this.$refs.taskManage.initSearch();
        },
        autoResize() {
            this.boxHeight = $('.sn_tablebody').height() - 10;
        }
    }
};
</script>
<style lang="scss" scoped>
#tourist_visitors {
    .sn_tablebody {
        top: 0;
    }
}
</style>