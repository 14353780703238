const commonData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'id',
            treeProps: {
                children: 'children',
                hasChildren: 'hasChildren'
            },
        },
        columns: {
            // 选择、序号
            columnsOperate: [{
                isShow: true,
                type: 'selection',
                selectMethod: 'showAllotBtn',
                width: 42
            }, {
                isShow: true,
                label: '序号',
                type: 'index',
                width: 50
            }],
            // 数据列
            columnsData: [{
                prop: 'jcpzfamc',
                label: '任务名称',
                minWidth: 135
            }, {
                prop: 'jcrwlx',
                label: '监测任务类型',
                minWidth: 105,
            }, {
                prop: 'zq',
                prop1: 'zqdw_bm',
                label: '采集周期',
                minWidth: 105
            }, {
                prop: 'zt',
                label: '采集状态',
                minWidth: 105,
                transList: {
                    0: '未开始',
                    1: '已开始',
                    2: '已暂停',
                    3: '已完成'
                },
                colorList: {
                    0: '#a0a0a0',
                    1: '#ff9066',
                    2: '#13ce67',
                    3: '#28ccd9'
                },
            }, {
                prop: 'sjcjfzrmc',
                label: '采集人',
                minWidth: 105
            }, {
                prop: 'jcqssj',
                label: '开始时间',
                minWidth: 165
            }, {
                prop: 'jcjssj',
                label: '结束时间',
                minWidth: 165
            }],
            // 操作列
            columnsBtn: {
                width: '230',
                buttonData: [{
                    isShow: true,
                    showMethod: 'showPauseBtn',
                    btnType: 'icon',
                    operateType: 'columnPause',
                    title: '暂停生成任务',
                    icon: 'fa fa-pause-circle-o one',
                    color: '#20a0ff'
                }, {
                    isShow: true,
                    showMethod: 'showPlayBtnByCJR',
                    btnType: 'icon',
                    operateType: '',
                    title: '请分配采集人再启动任务',
                    icon: 'fa fa-play-circle one',
                    color: '#20a0ff'
                }, {
                    isShow: true,
                    showMethod: 'showPlayBtn',
                    btnType: 'icon',
                    operateType: 'columnPlay',
                    title: '启动任务',
                    icon: 'fa fa-play-circle one',
                    color: '#20a0ff'
                }, {
                    isShow: true,
                    showMethod: 'showAllotBtn',
                    btnType: 'img',
                    operateType: 'columnAllot',
                    title: '分配任务',
                    src: 'monitoring_cloud/distribution.png',
                    class: 'two',
                }, {
                    isShow: true,
                    showMethod: 'showEndBtn',
                    btnType: 'icon',
                    operateType: 'columnEnd',
                    title: '结束任务',
                    icon: 'fa fa-lock three',
                    color: '#20a0ff'
                }, {
                    isShow: true,
                    // showMethod: '',
                    btnType: 'img',
                    operateType: 'columnInfo',
                    title: '查看详情',
                    src: 'monitoring_cloud/detail.png',
                    class: 'four',
                }, {
                    isShow: true,
                    showMethod: 'showMapBtn',
                    btnType: 'img',
                    operateType: 'columnToMap',
                    title: '地图',
                    src: 'monitoring_cloud/map.png',
                    class: 'five',
                }, {
                    isShow: true,
                    showMethod: 'showDelBtn',
                    btnType: 'icon',
                    operateType: 'columnDelete',
                    title: '删除',
                    icon: 'fa fa-trash-o six',
                    backColor: 'transparent',
                    color: 'red'
                }, {
                    isShow: true,
                    showMethod: 'showTmpTaskBtn',
                    btnType: 'img',
                    operateType: 'columnTmpTask',
                    title: '临时任务',
                    src: 'monitoring_cloud/TmpTask.svg',
                    class: 'seven',
                }]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    // snCount: {
    //     eachtotal: [10, 20, 50, 100],
    //     defaultTiaoshu: 10
    // },
    snSearch: {
        inputData: [{
            // label: '关键字',
            placeholder: "关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true
        }],
        selectData: [{
                // label: '监测任务类型',
                clearable: true,
                placeholder: "监测任务类型",
                class: 'selectRound',
                list: [],
                optionLabel: 'label',
                optionValue: 'value',
                value: 'rwlx',
                operateType: 'search',
                isShow: true
            },
            // {
            //     // label: '采集人',
            //     clearable: true,
            //     placeholder: "采集人",
            //     class: 'selectRound',
            //     width: '100px',
            //     list: [],
            //     optionLabel: 'REALNAME',
            //     optionValue: 'ID',
            //     value: 'cjrid',
            //     operateType: 'search',
            //     isShow: true,
            //     default: ""
            // }, 
            {
                // label: '任务状态',
                clearable: true,
                placeholder: "任务状态",
                class: 'selectRound',
                width: '100px',
                list: [{
                    ID: '0',
                    NAME: '未开始'
                }, {
                    ID: '1',
                    NAME: '已开始'
                }, {
                    ID: '2',
                    NAME: '已暂停'
                }, {
                    ID: '3',
                    NAME: '已完成'
                }],
                optionLabel: 'NAME',
                optionValue: 'ID',
                value: 'rwzt',
                operateType: 'search',
                isShow: true
            }
        ],
        treeselectData: [{
            clearable: true,
            searchable: true,
            placeholder: '采集人',
            class: 'selectRound',
            width: '160px',
            list: [],
            optionLabel: 'Name',
            optionValue: 'Id',
            value: 'cjrid',
            operateType: 'treesearch',
            isShow: true
        }],
        // dateData: [{
        //     isShow: true,
        //     label: '任务起止时间',
        //     clearable: true,
        //     placeholder: '任务起止时间',
        //     // type: 'daterange',
        //     rangeSeparator: "",
        //     startPlaceholder: "任务起止时间",
        //     endPlaceholder: ""
        // }],
        searchBtn: {
            buttonData: [{
                isShow: true,
                btnType: 'dropdown',
                dropdownClass: 'roundDropdown',
                menuList: [],
                operateType: 'buttonNew',
                name: '新增',
                round: true,
                // beforeIcon: 'el-icon-edit',
                afterIcon: 'el-icon-delete',
                backColor: '#2A63D6',
                // color: '#fff'
            }, {
                btnType: 'button',
                operateType: 'buttonAllot',
                name: '批量分配',
                round: true,
                backColor: '#2A63D6',
                color: '#fff'
            }]
        }
    }
};
// {
//     btnType: 'button',
//     operateType: 'buttonToMap',
//     name: '地图视图',
//     round: true,
//     backColor: '#13CE67',
//     color: '#fff'
// }
export { commonData };