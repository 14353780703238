import { render, staticRenderFns } from "./ethnic_culture_dialog.vue?vue&type=template&id=720229d8&scoped=true&"
import script from "./ethnic_culture_dialog.vue?vue&type=script&lang=js&"
export * from "./ethnic_culture_dialog.vue?vue&type=script&lang=js&"
import style0 from "./ethnic_culture_dialog.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ethnic_culture_dialog.vue?vue&type=style&index=1&id=720229d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "720229d8",
  null
  
)

export default component.exports