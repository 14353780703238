<template>
    <sn-dialog :dialogData.sync="dialogData">
        <el-form ref="ruleForm" :model="ruleForm.data[0].JcpzDtos" :rules="rules" label-width="110px" class="demo-ruleForm">
            <el-form-item label="监测周期" prop="Zq">
                <el-input v-model="ruleForm.data[0].JcpzDtos.Zq" size="mini" class="short-input" maxlength="5" @input="formatZq"></el-input>
                <el-radio-group v-model="ruleForm.data[0].JcpzDtos.Zqdw">
                    <el-radio :label="3">日</el-radio>
                    <el-radio :label="2">周</el-radio>
                    <el-radio :label="1">月</el-radio>
                    <el-radio :label="0">年</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="采集人" prop="SJCJFZRID">
                <el-select v-model="ruleForm.data[0].JcpzDtos.SJCJFZRID" size="mini" filterable placeholder="采集人" clearable>
                    <el-option v-for="(item, index) in cjrList" :key="index" :label="item.REALNAME" :value="item.ID"></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label-width="130px" label="是否立即下发任务" prop="RWSFBHDQZQ">
                <el-radio-group v-model="ruleForm.data[0].JcpzDtos.RWSFBHDQZQ">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                </el-radio-group>
            </el-form-item> -->
            <el-form-item v-if="btnItemShow" class="footer">
                <sn-button type="primary" :snButton.sync="snButton" @handleChange="handleChange"></sn-button>
            </el-form-item>
        </el-form>
    </sn-dialog>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: 'tourist_visitors_dialog',
    props: {
        dialogData: {
            default() {
                return {};
            }
        },
        zoneId: {
            type: String,
            default() {
                return '';
            }
        },
        btnItemShow: {
            type: Boolean,
            default() {
                return true;
            }
        }
    },
    data() {
        return {
            cjrList: [],
            ruleForm: {
                itemCode: '1103',
                jcdxId: '',
                isCustomJcwz: 0,
                data: [
                    {
                        JcwzDto: null,
                        JcpzDtos: {
                            Id: "",
                            Jcpzfamc: "",
                            Lrfs: "2",
                            Jcqssj: "",
                            Jcjssj: "",
                            Sfszqxjc: "1",
                            Zq: "",
                            Zqdw: 3,
                            Ds: "",
                            Sc: "",
                            Yjxq: "",
                            Bz: "",
                            SJCJFZRID: "",
                            RWSFBHDQZQ: "1", // 是否立即下发任务
                            CJSJ: ""
                        }
                    }
                ]
            },
            rules: {
                Zq: [
                    { min: 1, max: 5, message: '长度不能超过5', trigger: 'blur' }
                ]
            },
            snButton: {
                buttonData: [{
                    btnType: 'button',
                    operateType: 'buttonBack',
                    name: '返回',
                    round: true,
                    backColor: '#FF9065',
                    color: '#fff'
                }, {
                    btnType: 'button',
                    operateType: 'buttonSave',
                    name: '确定',
                    round: true,
                    backColor: '#2059CC',
                    color: '#fff'
                }]
            }
        };
    },
    created() {
        this.initUserList();
    },
    methods: {
        async getDetail(jgslid) {
            let obj = {
                id: 1103,
                jgslid: jgslid
            };
            let re = await this.getCorridorForm(obj);

            let result = re && re[0];
            let formData = this.ruleForm.data[0].JcpzDtos;
            formData.Id = result && result.ID || '';
            formData.Zq = result && result.ZQ || '';
            formData.Zqdw = result && result.ZQDW || 3;
            formData.SJCJFZRID = result && result.SJCJFZRID || '';
            formData.RWSFBHDQZQ = result && result.RWSFBHDQZQ || '1';
            formData.Jcqssj = result && result.JCQSSJ || '';
            formData.Sc = result.SC;
            formData.CJSJ = result && result.CJSJ || '';
        },
        ...mapActions([
            'getCjrList',
            'commonSaveform',
            'getCorridorForm'
        ]),
        handleClose() {

        },
        async handleChange(type) {
            if (type === 'buttonSave') {
                this.$refs['ruleForm'].validate(async (isValid) => {
                    if (isValid) {
                        this.ruleForm.jcdxId = this.zoneId;
                        this.ruleForm.data[0].JcpzDtos.Jcpzfamc = this.dialogData.title;
                        if (this.ruleForm.data[0].JcpzDtos.SJCJFZRID && this.ruleForm.data[0].JcpzDtos.Zq) {
                            this.ruleForm.data[0].JcpzDtos.zt = 1; // 启动任务
                        } else {
                            this.ruleForm.data[0].JcpzDtos.zt = 0;
                        }
                        let result  = await this.commonSaveform(this.ruleForm);
                        if (result.IsSuccess) {
                            this.$message({
                                type: 'success',
                                message: '保存成功！'
                            });
                            this.dialogData.dialog = false;
                            this.$emit('saveSuccess');
                        } else {
                            this.$message({
                                type: 'error',
                                message: '保存失败！'
                            });
                        }
                    } else {
                        this.$message({
                            type: 'warning',
                            message: '请检查是否填写完整！'
                        });
                    }
                });
            } else {
                this.dialogData.dialog = false;
            }
        },
        async initUserList() {
            this.cjrList = await this.getCjrList({
                funid: 'a6792d52-e29a-11e9-81a5-000c2977b7fd' // TODO
            });
        },
        formatZq() {
            this.ruleForm.data[0].JcpzDtos.Zq = this.ruleForm.data[0].JcpzDtos.Zq.replace(/[^0-9]/g, '').replace(/^0*/g, '');
        }
    }
};
</script>
<style lang="scss" scoped>
.demo-ruleForm {
    .el-form-item {
        margin-bottom: 15px;
        .short-input {
            width: 178px;
        }
        .el-radio-group {
            margin-top: 0px;
            margin-left: 15px;
            .el-radio {
                margin-right: 10px;
                    span.el-radio__label {
                        padding-left: 2px!important;
                    }
            }
        }
    }
    .footer {
        border-top: 1px dashed #BACBDF;
        text-align: right;
        padding-top: 20px;
    }
}
</style>