<template>
    <sn-dialog
        class="menu-dialog"
        :dialogData.sync="dialogData"
        @closeDialog="handleClose"
    >
        <div class="left-menu scrollbar-hidden">
            <ul>
                <i class="line"></i>
                <li
                    v-for="(item, index) in taskList"
                    :key="index"
                    class="task-item"
                    :class="{ active: index === currentIndex }"
                    @click="changeTask(index)"
                >
                    <span :title="lmObj[item]">
                        {{ lmObj[item] }}
                    </span>
                    <i
                        class="el-icon-close"
                        @click.stop="deleteTask(index)"
                    ></i>
                </li>
                <i
                    v-show="taskList.length < lmList.length"
                    class="add el-icon-plus"
                    @click="addTask"
                ></i>
            </ul>
        </div>
        <div class="right-content">
            <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="80px"
                class="demo-ruleForm"
            >
                <el-select
                    ref="selectLm"
                    v-model="ruleForm.lm"
                    class="selectRound select-item"
                    size="mini"
                    placeholder="选择立面"
                    :popper-append-to-body="false"
                    clearbale
                    @change="changeLM"
                    @click.native.stop="clickSelect"
                >
                    <el-option
                        v-for="(item, index) in lmList"
                        v-show="!taskList.includes(item.ID)"
                        :key="index"
                        :label="item.MC"
                        :value="item.ID"
                        :class="item.ID === currentIndex"
                    ></el-option>
                </el-select>
                <el-image
                    v-if="ruleForm.lm"
                    fit="contain"
                    class="lm-img"
                    :src="getPicurl(ruleForm.lm)"
                    v-viewer
                    :z-index="9999"
                ></el-image>
                <el-form-item label="监测周期" prop="Zq">
                    <el-input
                        v-model="ruleForm.Zq"
                        class="short-input"
                        size="mini"
                        maxlength="5"
                        @input="formatZq"
                    ></el-input>
                    <el-radio-group v-model="ruleForm.Zqdw">
                        <el-radio :label="3">日</el-radio>
                        <el-radio :label="2">周</el-radio>
                        <el-radio :label="1">月</el-radio>
                        <el-radio :label="0">年</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="采集人" prop="Sjcjfzrid">
                    <!-- <el-select v-model="ruleForm.Sjcjfzrid" size="mini" filterable placeholder="采集人" clearable>
                        <el-option v-for="(item, index) in cjrList" :key="index" :label="item.REALNAME" :value="item.ID"></el-option>
                    </el-select> -->
                    <SnTreeCjr
                        @handleChange="getCjrItem"
                        ref="SnTreeCjr"
                    ></SnTreeCjr>
                </el-form-item>
                <el-form-item label="监测描述" prop="Bz">
                    <el-input
                        v-model="ruleForm.Bz"
                        maxlength="500"
                        show-word-limit
                        type="textarea"
                        :rows="6"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item label-width="130px" label="是否立即下发任务" prop="Rwsfbhdqzq">
                    <el-radio-group v-model="ruleForm.Rwsfbhdqzq">
                        <el-radio label="1">是</el-radio>
                        <el-radio label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item v-if="btnItemShow" class="footer">
                    <sn-button
                        type="primary"
                        :snButton.sync="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </el-form-item>
            </el-form>
        </div>
    </sn-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "heritage_monomer_dialog",
    props: {
        dialogData: {
            default() {
                return {};
            },
        },
        btnItemShow: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },
    data() {
        return {
            basicInfo: {},
            basicForm: {}, // 配置的基本信息
            basicFormObj: {
                Id: "",
                Zbsjid: "",
                Jcwzmc: "",
                Jcwzsytmc: "",
                Jcwzsytlj: "",
                Jcwzms: "",
                Jcdxbid: "14",
                Jcwzdtxx: {
                    Id: "",
                    Sjid: "",
                    Zbxx: "",
                },
                YDDJZTLJ: "",
                YDDJZTMC: "",
                SFLYJCDX: 1,
            },
            ruleForm: {}, // 配置信息
            ruleFormObj: {
                Jcx: "602",
                lm: "", // 表单存了立面图的id
                Id: "", // 监测配置的ID,
                Jcpzfamc: "", // 监测配置方案名称
                Zbx: "", // 指标项
                Lrfs: "2", // "录入方式", 移动端为2
                Jcqssj: "", // 监测起始时间
                Jcjssj: "", // 监测结束时间
                Sfszqxjc: "1", // 是否是周期性监测：0-否，1-是
                Zq: "", // 周期
                Zqdw: 3, // 周期单位
                Ds: "", // 定时
                Sc: "", // 时长
                Yjxq: "", // 预警需求
                Bz: "", // 监测描述，最大500字，
                Sjcjfzrid: "", // 数据采集负责人ID
                Rwsfbhdqzq: "1",
            },
            rules: {
                lm: [
                    {
                        required: true,
                        message: "请选择立面",
                        trigger: "change",
                    },
                ],
            },
            cjrList: [],
            currentIndex: 0,
            lmList: [], // 所有的立面列表
            lmObj: {},
            taskList: [], // 当前配置的立面列表
            configList: [],
            snButton: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "buttonBack",
                        name: "返回",
                        round: true,
                        backColor: "#FF9065",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "buttonSave",
                        name: "确定",
                        round: true,
                        backColor: "#2059CC",
                        color: "#fff",
                    },
                ],
            },
            sjid: "",
            deleteIds: [],
        };
    },
    watch: {},
    mounted() {
        this.initUserList();
    },
    methods: {
        ...mapActions([
            "getCjrList",
            "getCorridorTask",
            "getCorridorForm",
            "getJbtuData",
            "commonSaveform",
            "DeleteJcpz",
        ]),
        // 隐藏下拉框
        clickSelect() {
            if (
                this.taskList.filter((item) => item).length ===
                this.lmList.length
            ) {
                this.$refs.selectLm.popperElm.style.display = "none";
            } else {
                this.$refs.selectLm.popperElm.style.display = "inherit";
            }
        },
        handleClose() {
            this.$emit("updateMap");
        },
        getDetail(value, lmid) {
            this.sjid = value;
            this.gettaskList(lmid);
        },
        // 获取图片路径
        getPicurl(value) {
            let data = this.lmList.filter((item) => item.ID === value);
            if (data[0] && data[0].PIC[0]) {
                return data[0].PIC[0].SYT;
            }
            return "";
        },
        // 切换立面图
        changeLM(value) {
            // 如果有之前的数据需要做删除
            if (this.ruleForm.Id) {
                this.deleteIds.push(this.ruleForm.Id);
                this.ruleForm.Id = "";
            }
            this.ruleForm.Bz =
                this.ruleForm.Bz ||
                this.lmList.filter((item) => item.ID === value)[0].BZ;
            this.basicInfo = this.lmList.filter((item) => item.ID === value)[0];
            this.basicForm = this.getJcwzDto();
            this.taskList[this.currentIndex] = value;
            this.$forceUpdate();
        },
        // 增加任务
        addTask() {
            if (this.ruleForm.lm) {
                this.saveData();
            }
            this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormObj));
            this.taskList.push("");
            this.currentIndex = this.taskList.length - 1;
        },
        // 删除任务
        deleteTask(index) {
            this.taskList.splice(index, 1);
            this.getFromdata(this.taskList[0]);
            this.currentIndex = 0;
            if (this.ruleForm.Id) {
                this.DeleteJcpz({ jcpzid: this.ruleForm.Id });
            }
        },
        // 切换任务
        changeTask(id) {
            if (this.ruleForm.lm) {
                this.saveData();
            }
            this.getFromdata(this.taskList[id]);
            this.currentIndex = id;
        },
        // 采集人列表
        async initUserList() {
            this.cjrList = await this.getCjrList({
                funid: "f20dd133-de6e-11e9-81a5-000c2977b7fd",
            });
        },
        // 获取立面图列表和任务列表
        async gettaskList(lmid) {
            this.lmList = await this.getJbtuData({
                id: "14",
                ycysbm: this.sjid,
            });
            this.lmObj = {};
            this.lmList.forEach((item) => {
                this.lmObj[item.ID] = item.MC;
            });
            let result = await this.getCorridorTask({
                ycysbm: this.sjid,
            });
            this.taskList = result.map((item) => item.id);
            // 监测配置列表处定位
            this.currentIndex =
                this.taskList.indexOf(lmid) === -1
                    ? 0
                    : this.taskList.indexOf(lmid);
            this.deleteIds = [];
            if (this.taskList.length === 0) {
                // 清空表单数据
                this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormObj));
                // 未配置时默认选中一项
                if (this.lmList[0]) {
                    this.ruleForm.lm = this.lmList[0].ID;
                    this.changeLM(this.lmList[0].ID);
                }
            } else {
                this.getFromdata(this.taskList[this.currentIndex]);
            }
        },
        getJcwzDto() {
            let basicForm = JSON.parse(JSON.stringify(this.basicFormObj));
            let obj = {
                Zbsjid: this.basicInfo.ID && this.basicInfo.ID,
                Jcwzsytlj:
                    this.basicInfo.PIC &&
                    this.basicInfo.PIC[0] &&
                    this.basicInfo.PIC[0].SYT,
                YDDJZTLJ:
                    this.basicInfo.PIC &&
                    this.basicInfo.PIC[0] &&
                    this.basicInfo.PIC[0].SYT,
                Jcwzms: this.basicInfo.BZ && this.basicInfo.BZ,
                Jcwzdtxx: {
                    Id: "",
                    Sjid: "",
                    Zbxx: "",
                },
            };
            Object.keys(obj).forEach((key) => {
                basicForm[key] = obj[key];
            });
            return basicForm;
        },
        // 获取单个配置表单数据
        async getFromdata(id) {
            let _this = this;
            let result = await _this.getCorridorForm({
                id: "602",
                jgslid: id,
            });
            _this.configList = (result &&
                result.jcwzAndJcpzDtos &&
                result.jcwzAndJcpzDtos.length > 0 &&
                result.jcwzAndJcpzDtos) || [{}];
            // TODO
            if (_this.configList[0] && _this.configList[0].JcwzDto) {
                _this.basicForm = _this.configList[0].JcwzDto;
                if (!_this.basicForm.Jcwzdtxx) {
                    _this.basicForm.Jcwzdtxx = {
                        Id: "",
                        Sjid: "",
                        Zbxx: "",
                    };
                }
            } else {
                _this.basicForm = _this.getJcwzDto();
                _this.configList[0].JcwzDto = _this.basicForm;
            }
            if (_this.configList[0] && _this.configList[0].JcpzDtos) {
                _this.ruleForm = _this.configList[0].JcpzDtos;
                _this.ruleForm.lm = _this.taskList[_this.currentIndex];
            } else {
                _this.ruleForm = JSON.parse(JSON.stringify(_this.ruleFormObj));
                _this.configList[0].JcpzDtos = _this.ruleForm;
            }

            _this.$refs.SnTreeCjr.setLabelName(_this.ruleForm.Sjcjfzrid);
        },
        async saveData(type) {
            this.snButton.buttonData[1].disabled = true;
            let title = this.dialogData.title.split("-");
            this.ruleForm.Jcpzfamc =
                title[0] + "-" + this.lmObj[this.ruleForm.lm] + "-" + title[1];
            if (this.ruleForm.Sjcjfzrid && this.ruleForm.Zq) {
                this.ruleForm.Zt = "1";
            } else {
                this.ruleForm.Zt = "0";
            }

            // 判断是否“周”的周期ZQDW==“2”，判断是否周五、六、日，
            // 若是，RWSFBHDQZQ值为“0”，不立即下发任务，下个周期再下发
            if (+this.ruleForm.Zqdw === 2) {
                let dayArr = [5, 6, 0];
                if (dayArr.indexOf(new Date().getDay()) !== -1) {
                    this.ruleForm.Rwsfbhdqzq = 0;
                }
            }

            this.configList = [
                {
                    JcwzDto: this.basicForm,
                    JcpzDtos: this.ruleForm,
                },
            ];
            let result = await this.commonSaveform({
                itemCode: "602",
                jcdxId: this.ruleForm.lm,
                isCustomJcwz: 1,
                data: this.configList,
            });
            // 主动保存有提示
            if (type && result.IsSuccess) {
                this.$message({
                    type: "success",
                    message: "保存成功",
                });
                this.dialogData.dialog = false;
                this.$emit("saveSuccess");
            }
            this.deleteIds.forEach((item) => {
                this.DeleteJcpz({ jcpzid: item });
            });
            // Id赋值避免重复保存
            await this.getFromdata(this.taskList[this.currentIndex]);
            this.snButton.buttonData[1].disabled = false;
        },
        // 操作按钮
        handleChange(value) {
            if (value === "buttonSave") {
                if (this.ruleForm.lm) {
                    if (!this.snButton.buttonData[1].disabled) {
                        this.saveData("buttonSave");
                    }
                } else {
                    this.$message({
                        type: "warning",
                        message: "请选择立面",
                    });
                }
            } else {
                this.dialogData.dialog = false;
            }
        },
        // 过滤采集周期 只允许输入数字且第一位不能为0
        formatZq() {
            this.ruleForm.Zq = this.ruleForm.Zq.replace(/[^0-9]/g, "").replace(
                /^0*/g,
                ""
            );
        },
        getCjrItem(id) {
            this.ruleForm.Sjcjfzrid = id;
            this.$refs.SnTreeCjr.setLabelName(this.ruleForm.Sjcjfzrid);
        },
    },
};
</script>
<style lang="scss" scoped>
.menu-dialog {
    position: relative;
    .left-menu {
        width: 40px;
        // min-height: 200px;
        // max-height: calc(100% - 60px);
        height: calc(100% - 60px);
        position: absolute;
        top: 20px;
        left: 40px;
        ul {
            position: absolute;
            .line {
                width: 4px;
                height: calc(100% + 50px);
                display: block;
                position: absolute;
                top: 0;
                right: -4px;
                background-color: #336799; //#819fc1;
            }
            .task-item {
                width: 30px;
                height: 130px;
                line-height: 30px;
                text-align: center;
                writing-mode: tb-rl;
                color: #333335;
                position: relative;
                cursor: pointer;
                &.active {
                    color: #fff;
                    // color: #fff;
                    // TODO 图片要替换
                    background: url("~@image/monitoring_cloud/leftNameBg.png");
                    .el-icon-close {
                        display: block;
                        position: absolute;
                        bottom: 10px;
                        right: 50%;
                        transform: translateX(50%);
                        font-weight: 700;
                        color: #fff;
                        cursor: pointer;
                    }
                }
                span {
                    height: 100%;
                    display: inline-block;
                    padding-bottom: 20px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    padding-top: 10px;
                }
                .el-icon-close {
                    display: none;
                }
            }
            .add {
                width: 28px;
                margin-top: 15px;
                color: #1e5acc;
                font-size: 16px;
                line-height: 50px;
                text-align: center;
                font-weight: 700;
                border-top: 1px solid #d4ddec;
                cursor: pointer;
            }
        }
    }
    .right-content {
        width: calc(100% - 42px);
        padding-left: 80px;
        .demo-ruleForm {
            .el-form-item {
                margin-bottom: 15px;
                .short-input {
                    // width: calc(100% - 200px);
                    width: 178px;
                }
                .el-radio-group {
                    // float: right;
                    margin-top: 0px;
                    margin-left: 8px;
                    .el-radio {
                        margin-right: 10px;
                        span.el-radio__label {
                            // TODO 不生效
                            padding-left: 2px !important;
                        }
                    }
                }
            }
            .select-item {
                margin-bottom: 15px;
            }
            .lm-img {
                display: block;
                margin-bottom: 15px;
                max-height: 300px;
            }
            .footer {
                border-top: 1px dashed #bacbdf;
                text-align: right;
                padding-top: 20px;
            }
        }
    }
}
</style>