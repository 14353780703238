<template>
    <div>
        <rcxcDialog
            ref="dialog76"
            :dialogData.sync="dialogData76"
            :zoneId="sjid"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></rcxcDialog>
        <lyykDialog
            ref="dialog1103"
            :dialogData.sync="dialogData1103"
            :zoneId="sjid"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></lyykDialog>
        <ycysdtDialog
            ref="dialog602"
            :dialogData.sync="dialogData602"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></ycysdtDialog>
        <btbhDialog
            ref="dialog70502"
            :dialogData.sync="dialogData70502"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></btbhDialog>
        <bhgcDialog
            ref="dialog1503"
            :dialogData.sync="dialogData1503"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></bhgcDialog>
        <xjxmDialog
            ref="dialog90203"
            :dialogData.sync="dialogData90203"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></xjxmDialog>
        <mjjsDialog
            ref="dialog90502"
            :dialogData.sync="dialogData90502"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></mjjsDialog>
        <kgfjDialog
            ref="dialog1403"
            :dialogData.sync="dialogData1403"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></kgfjDialog>
        <sn-dialog
            :dialogData="dialogData751"
            @closeDialog="dialogData751.dialog = false"
        >
            <yjgzDialog
                ref="dialog751"
                :dialogInfo="dialogData751.tableData"
                :btnItemShow="btnItemShow"
                @saveSuccess="saveSuccess"
            ></yjgzDialog>
        </sn-dialog>
        <klgfDialog
            ref="dialog1105"
            :dialogData.sync="dialogData1105"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></klgfDialog>
        <jgslDialog
            ref="dialog90302"
            :dialogData.sync="dialogData90302"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></jgslDialog>
        <jgfmDialog
            ref="dialog90304"
            :dialogData.sync="dialogData90304"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></jgfmDialog>
        <mzwhDialog
            ref="dialog500202"
            :dialogData.sync="dialogData500202"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></mzwhDialog>
        <mjkpDialog
            ref="dialog30801"
            :dialogData.sync="dialogData30801"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></mjkpDialog>
        <gcsjcDialog
            ref="dialog30701"
            :dialogData.sync="dialogData30701"
            :btnItemShow="btnItemShow"
            @saveSuccess="saveSuccess"
        ></gcsjcDialog>
    </div>
</template>
<script>
import rcxcDialog from "./daily_patrol/comp/PointDialog"; // 日常巡查
import lyykDialog from "./tourist_visitors/tourist_visitors_dialog"; // 旅游游客
import ycysdtDialog from "./heritage_monomer/heritage_monomer_dialog"; // 遗产要素
import btbhDialog from "./ontology_disease/ontology_disease_dialog"; // 本体病害
import bhgcDialog from "./protection_works/protection_works_dialog"; // 保护工程
import xjxmDialog from "./new_project/new_project_dialog"; // 新建项目
import mjjsDialog from "./monitor_residential_construct/dialog"; // 民居建设
import kgfjDialog from "./archaeology_dig/archaeology_dig_dialog"; // 考古发掘
import yjgzDialog from "../abnormal_warning/detail/index"; // 预警跟踪
import jgslDialog from "./viewing_corridor/viewing_corridor_dialog"; // 景观视线
import jgfmDialog from "./landscape_style/landscape_style_dialog"; // 景观风貌
import klgfDialog from "./commuter_rush_hour/commuter_rush_hour_dialog"; // 客流高峰
import mzwhDialog from "./ethnic_culture/ethnic_culture_dialog"; // 民族文化
import mjkpDialog from "./residential_assess/residential_assess_dialog"; // 民居考评
import gcsjcDialog from "./ancient_tea_tree/comp/PointDialog"; // 古茶树监测
export default {
    name: "dialog_gather",
    components: {
        rcxcDialog,
        lyykDialog,
        ycysdtDialog,
        btbhDialog,
        bhgcDialog,
        yjgzDialog,
        xjxmDialog,
        mjjsDialog,
        kgfjDialog,
        jgslDialog,
        jgfmDialog,
        klgfDialog,
        mzwhDialog,
        mjkpDialog,
        gcsjcDialog,
    },
    props: {
        btnItemShow: {
            default() {
                return true;
            },
        },
    },
    data() {
        return {
            sjid: "",
            dialogData76: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData1103: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData110302: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData602: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData70502: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData1503: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData90203: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData90502: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData1403: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData1105: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData90302: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData90304: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData751: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
                tableData: {},
            },
            dialogData500202: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData30801: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            dialogData30701: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
        };
    },
    mounted() {},
    methods: {
        showDialog(val) {
            console.log("showDialog", val);
            let id = val.jcx;
            let sjid = "";
            let method = "getDetail";
            this["dialogData" + id].title = val.jcpzfamc;
            switch (id) {
                case "76": //日常巡查
                    // 组件里main 用id 但是传的值是jcwzid
                    val.id = val.jcwzid;
                    sjid = val;
                    break;
                case "1103": //旅游游客
                    sjid = val.id;
                    this["dialogData" + id].title = val.jcpzfamc;
                    break;
                case "1105": //客流高峰现场照片
                    sjid = [val.jcdxid, val.jcwzid];
                    this["dialogData" + id].title = val.jcpzfamc;
                    break;
                case "1503": //保护工程
                    sjid = [
                        val.jcdxid,
                        val.jcpzfamc.slice(0, val.jcpzfamc.indexOf("-")),
                    ];
                    break;
                case "90203": //新建项目
                    sjid = [
                        val.jcdxid,
                        val.jcpzfamc.slice(0, val.jcpzfamc.indexOf("-")),
                    ];
                    break;
                case "90502": //民居建设
                    sjid = [
                        val.jcdxid,
                        val.jcpzfamc.slice(0, val.jcpzfamc.indexOf("-")),
                    ];
                    break;
                case "1403": //考古发掘
                    sjid = [
                        val.jcdxid,
                        val.jcpzfamc.slice(0, val.jcpzfamc.indexOf("-")),
                    ];
                    break;
                case "602": //遗产要素
                    sjid = val.ycysbm;
                    this["dialogData" + id].title =
                        val.ycysmc + "-监测任务配置";
                    break;
                case "90302": //景观视线
                    sjid = val.jcdxid;
                    break;
                case "90304": //景观风貌
                    sjid = val.jcdxid;
                    break;
                case "70502": //本体病害
                    sjid = {
                        Id: val.jcdxid,
                        ycysbm: val.ycysbm,
                        ycysmc: val.ycysmc,
                        size: "1",
                    };
                    break;
                case "751": //异常预警跟踪
                    sjid = val.jcdxid;
                    method = "getToTaskDetails";
                    this["dialogData" + id].tableData.ID = val.jcdxid;
                    this["dialogData" + id].tableData.QYID = val.qyid;
                    break;
                case "500202": //民族文化
                    sjid = val.jcdxid;
                    break;
                case "30801": //民居考评
                    sjid = val.jcdxid;
                    break;
                case "30701": //古茶树监测
                    this["dialogData" + id].title =
                        val.jcpzfamc + "-古茶树监测配置";
                    break;
            }

            if (val.zt === "3") {
                this.$emit("update:btnItemShow", false);
            } else {
                this.$emit("update:btnItemShow", true);
            }
            this["dialogData" + id].dialog = true;
            if (id === "30701") {
                let obj = JSON.parse(JSON.stringify(val));
                obj.id = obj.jcdxid;
                this.$nextTick(() => {
                    this.$refs["dialog" + id][method](obj);
                });
            } else {
                this.$nextTick(() => {
                    this.$refs["dialog" + id][method](
                        sjid,
                        val.jcdxid,
                        val.jcwzid
                    );
                });
            }
        },
        saveSuccess() {
            this.$emit("saveSuccess");
        },
    },
};
</script>
<style lang="scss" scoped>
</style>