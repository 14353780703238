<template>
    <sn-dialog
        class="menu-dialog"
        :dialogData.sync="dialogData"
        @closeDialog="closeDisesaseDialog"
    >
        <div class="content-box">
            <div class="basic-data">
                <el-form
                    ref="ruleForm"
                    :model="ruleForm"
                    :rules="rules"
                    label-width="120px"
                    class="demo-ruleForm"
                    ><el-form-item class="set-monitor" label="基本信息">
                    </el-form-item>
                    <div style="width: 100%" class="flex-between">
                        <div style="width: 50%">
                            <el-form-item label="编号" size="mini">
                                <el-input
                                    v-model="diseaseItem.XJXMBH"
                                    disabled
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="村" size="mini">
                                <el-input
                                    v-model="diseaseItem.YCCZ"
                                    disabled
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="组" size="mini">
                                <el-input
                                    v-model="diseaseItem.YCYS"
                                    disabled
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="监测日期" size="mini">
                                <el-input
                                    v-model="diseaseItem.CJSJ"
                                    disabled
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="宅基地情况" size="mini">
                                <el-input
                                    v-model="diseaseItem.ZJDQK"
                                    disabled
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div style="width: 50%">
                            <el-form-item label="户主姓名" size="mini">
                                <el-input
                                    v-model="diseaseItem.HZXM"
                                    disabled
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话" size="mini">
                                <el-input
                                    v-model="diseaseItem.MOBILE"
                                    disabled
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="建设方案是否通过审批"
                                size="mini"
                            >
                                <el-input
                                    v-model="diseaseItem.SFSPTG"
                                    disabled
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item class="set-monitor" label="" prop="">
                    </el-form-item>
                    <div class="task-data">
                        <div class="left-menu scrollbar-hidden">
                            <ul>
                                <i class="line"></i>
                                <li
                                    v-for="(item, index) in configList"
                                    :key="index"
                                    class="task-item"
                                    :class="{ active: index === currentIndex }"
                                    :title="item.JcwzDto.Jcwzmc"
                                >
                                    <span
                                        v-if="
                                            item.JcwzDto.Jcwzmc &&
                                            item.JcwzDto.Jcwzmc.length > 5
                                        "
                                        @click="changeTask(index)"
                                    >
                                        {{ item.JcwzDto.Jcwzmc.slice(0, 5) }}···
                                    </span>
                                    <span
                                        v-if="
                                            item.JcwzDto.Jcwzmc &&
                                            item.JcwzDto.Jcwzmc.length <= 5
                                        "
                                        @click="changeTask(index)"
                                    >
                                        {{ item.JcwzDto.Jcwzmc }}
                                    </span>
                                    <i
                                        class="el-icon-close"
                                        @click="deleteForm(index, item)"
                                    ></i>
                                </li>
                                <i
                                    class="add el-icon-plus"
                                    @click="addForm"
                                ></i>
                            </ul>
                        </div>
                        <div class="right-content" style="padding-left: 40px">
                            <el-form-item
                                label="是否来源于监测对象"
                                prop="bhlx"
                                label-width="150px"
                            >
                                <el-radio-group v-model="basicForm.Sflyjcdx">
                                    <el-radio :label="0">否</el-radio>
                                    <el-radio :label="1">是</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <div>
                                <el-form-item
                                    label="监测点名称"
                                    size="mini"
                                    :label-width="labelWidthLarge"
                                >
                                    <el-input
                                        v-model="basicForm.Jcwzmc"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="监测位置"
                                    :label-width="labelWidthLarge"
                                >
                                    <div
                                        v-if="
                                            basicForm.Jcwzdtxx &&
                                            basicForm.Jcwzdtxx.Zbxx &&
                                            JSON.parse(basicForm.Jcwzdtxx.Zbxx)
                                                .length !== 0
                                        "
                                    >
                                        <span class="is-sign">已标记</span>
                                        <img
                                            style="cursor: pointer"
                                            src="@image/monitoring_cloud/hasmark.png"
                                            @click="openDraw"
                                        />
                                    </div>
                                    <div v-else>
                                        <span class="is-sign">未标记</span>
                                        <img
                                            style="cursor: pointer"
                                            src="@image/monitoring_cloud/nomark.png"
                                            @click="openDraw"
                                        />
                                    </div>
                                </el-form-item>
                                <el-form-item
                                    label="监测位置描述"
                                    prop="Jcwzms"
                                    :label-width="labelWidthLarge"
                                >
                                    <el-input
                                        v-model="basicForm.Jcwzms"
                                        size="mini"
                                        type="textarea"
                                        :rows="6"
                                    ></el-input>
                                </el-form-item>
                            </div>
                            <el-form-item
                                label="监测周期"
                                prop="Zqdw"
                                :label-width="labelWidthLarge"
                            >
                                <el-input
                                    v-model="ruleForm.Zq"
                                    class="short-input"
                                    size="mini"
                                    maxlength="5"
                                    @input="formatZq"
                                ></el-input>
                                <el-radio-group v-model="ruleForm.Zqdw">
                                    <el-radio :label="3">日</el-radio>
                                    <el-radio :label="2">周</el-radio>
                                    <el-radio :label="1">月</el-radio>
                                    <el-radio :label="0">年</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item
                                label="采集人"
                                prop="Sjcjfzrid"
                                :label-width="labelWidthLarge"
                            >
                                <SnTreeCjr
                                    @handleChange="getCjrItem"
                                    ref="SnTreeCjr"
                                ></SnTreeCjr>
                            </el-form-item>
                            <el-form-item v-if="btnItemShow" class="footer">
                                <sn-button
                                    type="primary"
                                    :snButton.sync="snButton"
                                    @handleChange="handleChange"
                                ></sn-button>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <sn-dialog
            class="menu-dialog"
            :appendToBody="true"
            :dialogData.sync="dialogDraw"
        >
            <div class="drawBox">
                <div v-if="dialogDraw.dialog">
                    <mapDraw
                        ref="myMapDraw"
                        :basicMap="taskMap"
                        :drawConf="drawConf"
                        :jsonStr="basicForm.Jcwzdtxx.Zbxx"
                        @update:jsonStr="getJsonStr"
                    >
                    </mapDraw>
                    <Mymap
                        :mapData="mapData"
                        class="map"
                        ref="myMap"
                        @initmap="initMap"
                    ></Mymap>
                </div>
            </div>
        </sn-dialog>
    </sn-dialog>
</template>
<script>
import { mapActions } from "vuex";
import mapDraw from "@comp/map/mapDraw/MapDraw";
export default {
    name: "",
    components: {
        mapDraw,
    },
    props: {
        dialogData: {
            default() {
                return {};
            },
        },
        ycysMc: {
            type: String,
            default() {
                return "";
            },
        },
        btnItemShow: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },
    data() {
        return {
            mapData: {
                dxtState: true, //地形图
                maxZoom: 20,
                zoom: 12,
                areaState: true,
            },
            BASE_URL: window.REQUEST_URL,
            DOWN_URL: window.DOWN_URL,
            currentId: 90502,
            configList: [],
            basicForm: {}, // 配置基本信息
            ruleForm: {}, // 配置表单
            rules: {},
            cjrList: [],
            currentIndex: 0,
            snButton: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "buttonBack",
                        name: "返回",
                        round: true,
                        backColor: "#FF9065",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "buttonSave",
                        name: "确定",
                        round: true,
                        backColor: "#186d70",
                        color: "#fff",
                    },
                ],
            },
            sjid: "",
            jcwzid: "",
            labelWidthLarge: "100px",
            labelWidthSmall: "80px",
            taskMap: null,
            areaLayers: null,
            dialogDraw: {
                dialog: false,
                title: "标绘监测位置",
            },
            drawConf: {
                show: true,
                draw: {
                    polygon: false,
                    polyline: false,
                },
            },
            jsonStr: null,
            diseaseItem: {},
            ycysName: "",
        };
    },
    mounted() {
        this.getCjrData();
    },
    methods: {
        ...mapActions([
            "commonSaveform",
            "getCjrList",
            "getCorridorForm",
            "getJbtuData",
            "DeleteConfigure",
        ]),
        changeTask(ind) {
            this.currentIndex = ind;
            this.fileList = [];
            this.basicForm = this.configList[ind].JcwzDto;
            this.ruleForm = this.configList[ind].JcpzDtos;
            this.$refs.SnTreeCjr.setLabelName(this.ruleForm.Sjcjfzrid);
        },
        handleChange(type) {
            if (type === "buttonBack") {
                this.dialogData.dialog = false;
            } else if (type === "buttonSave") {
                if (!this.snButton.buttonData[1].disabled) {
                    this.saveData();
                }
            }
        },
        async saveData() {
            this.snButton.buttonData[1].disabled = true;
            if (!this.isHasDuplicateName()) {
                this.common.showMsg("监测点名称重复，请修改后保存", "warning");
                this.snButton.buttonData[1].disabled = false;
                return false;
            }
            this.configList.forEach((v, ind) => {
                v.JcwzDto.Px = ind;
                if (this.diseaseItem.XJXMBH) {
                    v.JcpzDtos.Jcpzfamc = `${this.diseaseItem.XJXMBH}-${v.JcwzDto.Jcwzmc}-监测任务配置`;
                } else {
                    v.JcpzDtos.Jcpzfamc = `${v.JcwzDto.Jcwzmc}-监测任务配置`;
                }
                // if (
                //     v.JcwzDto.YDDJZTLJ &&
                //     v.JcwzDto.Jcwzmc &&
                //     v.JcpzDtos.Sjcjfzrid &&
                //     v.JcpzDtos.Zq
                // ) {
                //     v.JcpzDtos.Zt = 1;
                // } else {
                //     v.JcpzDtos.Zt = 0;
                // }
                v.JcpzDtos.Zt = 1;

                // 判断是否“周”的周期ZQDW==“2”，判断是否周五、六、日，
                // 若是，RWSFBHDQZQ值为“0”，不立即下发任务，下个周期再下发
                if (v.JcpzDtos.Zqdw === 2) {
                    let dayArr = [5, 6, 0];
                    if (dayArr.indexOf(new Date().getDay()) !== -1) {
                        v.JcpzDtos.Rwsfbhdqzq = 0;
                    }
                }
            });
            let res = await this.commonSaveform({
                itemCode: this.currentId,
                jcdxId: this.diseaseItem.Id,
                isCustomJcwz: 1,
                data: this.configList,
            });
            if (res.IsSuccess) {
                this.common.showMsg("保存成功", "success");
                this.dialogData.dialog = false;
                this.$emit("saveSuccess");
            } else {
                this.common.showMsg("保存失败", "error");
            }
            this.snButton.buttonData[1].disabled = false;
        },
        // 获得采集人列表
        async getCjrData() {
            let res = await this.getCjrList({
                funid: "e2c46679-9358-11ea-bd8d-000c2977b7fd",
            });
            this.cjrList = res;
        },
        // 点击列表中的查看详情
        async getDetail(item, dxid, wzid) {
            this.currentIndex = 0;
            this.getBasicData(item);
            this.sjid = item[0];
            // 基本信息
            this.diseaseItem.Id = this.sjid;
            this.jcwzid = wzid;
            this.getConfigList();
        },
        // 获取工程基础信息
        async getBasicData(val) {
            let res = await this.getJbtuData({
                id: this.currentId,
                ycysbm: val[0],
            });
            let da = res[0];
            this.diseaseItem = da;
            this.diseaseItem.Id = this.sjid;
            this.jsonStr = da.XMWZ;
        },
        // 获取配置列表
        async getConfigList() {
            let result = await this.getCorridorForm({
                id: this.currentId,
                jgslid: this.diseaseItem.Id,
            });
            this.configList =
                result.jcwzAndJcpzDtos && result.jcwzAndJcpzDtos.length !== 0
                    ? result.jcwzAndJcpzDtos
                    : [{}];
            let index = 0;
            if (this.jcwzid) {
                index = this.configList.findIndex(
                    (item) => item.JcwzDto && item.JcwzDto.Id == this.jcwzid
                );
                index = index == -1 ? 0 : index;
            }
            this.currentIndex = index;
            if (this.configList[index] && this.configList[index].JcwzDto) {
                this.basicForm = this.configList[index].JcwzDto;
                if (!this.basicForm.Jcwzdtxx) {
                    this.basicForm.Jcwzdtxx = {
                        Id: "",
                        Sjid: "",
                        Zbxx: "",
                    };
                }
            } else {
                this.basicForm = this.getJcwzDto();
                this.configList[index].JcwzDto = this.basicForm;
            }
            if (this.configList[index] && this.configList[index].JcpzDtos) {
                this.ruleForm = this.configList[index].JcpzDtos;
            } else {
                this.ruleForm = this.getJcpzDtos();
                this.configList[index].JcpzDtos = this.ruleForm;
            }
            this.$refs.SnTreeCjr.setLabelName(this.ruleForm.Sjcjfzrid);
        },
        // 删除表单
        deleteForm(ind, item) {
            if (item && item.JcpzDtos && item.JcpzDtos.Id) {
                let id = item.JcpzDtos.Id;
                this.$confirm("是否永久删除该条记录?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.deleteData(ind, id);
                    })
                    .catch(() => {
                        this.common.showMsg("已取消删除", "info");
                    });
            } else {
                this.configList.splice(ind, 1);
                if (this.configList.length === 0) {
                    this.dialogData.dialog = false;
                } else {
                    this.changeTask(0);
                }
            }
        },
        // 调用服务 删除配置
        async deleteData(ind, id) {
            let res = await this.DeleteConfigure({
                jcpzid: id,
            });
            if (res.IsSuccess) {
                this.common.showMsg("删除成功", "success");
                this.configList.splice(ind, 1);
                if (this.configList.length === 0) {
                    this.$emit("saveSuccess");
                    this.dialogData.dialog = false;
                } else {
                    this.changeTask(0);
                }
            } else {
                this.common.showMsg("删除失败", "error");
            }
        },
        // 添加表单
        addForm() {
            this.configList.push({});
            let ind = this.configList.length - 1;
            this.configList[ind].JcwzDto = this.getJcwzDto();
            this.configList[ind].JcpzDtos = this.getJcpzDtos();
            this.changeTask(ind);
        },
        getJcwzDto() {
            let name = this.avoidDuplicateName(1, "监测点");
            let basicForm = {
                Id: "",
                Zbsjid: this.diseaseItem.Id,
                Jcwzmc: name,
                Jcwzsytmc: "",
                Jcwzsytlj: "",
                Jcwzms: this.ruleForm.Jcwzms,
                Jcdxbid: "90501",
                Jcwzdtxx: {
                    Id: "",
                    Sjid: "",
                    Zbxx: "",
                },
                YDDJZTLJ: "",
                YDDJZTMC: "",
                Sflyjcdx: 0, //0 否 1 是
            };
            return basicForm;
        },
        getJcpzDtos() {
            let name = this.ycysMc || this.ycysName;
            let ruleForm = {
                Id: "",
                Jcpzfamc: `${name}-监测任务配置`,
                Zbx: null,
                Lrfs: 2,
                Jcqssj: null,
                Jcjssj: null,
                Sfszqxjc: 1, //是否来源于监测对象
                Zq: "", //周期
                Zqdw: 3, //周期单位
                Ds: null,
                Sc: null,
                Yjxq: null,
                Bz: null, //备注
                Rwsfbhdqzq: "1",
            };
            return ruleForm;
        },
        // 改变配置
        changePz() {
            this.configList = [];
            this.addForm();
        },
        initMap(map) {
            this.taskMap = map;
            this.areaLayers = L.featureGroup().addTo(this.taskMap);
            this.initZone();
        },
        // 打开标绘组件
        openDraw() {
            this.dialogDraw.dialog = true;
        },
        // 渲染保护范围
        initZone() {
            this.areaLayers.clearLayers();
            if (!this.jsonStr) return false;
            let geoJson = JSON.parse(this.jsonStr);
            L.geoJSON(geoJson, {
                onEachFeature: (feature, layer) => {
                    this.areaLayers.addLayer(layer);
                },
                style() {
                    return {
                        color: "#1862ad",
                        fillColor: "#1862ad",
                        weight: 2,
                    };
                },
            });
        },
        // 标绘操作
        getJsonStr(JsonStr, event) {
            let jsonStr = JSON.parse(JsonStr);
            if (jsonStr.length === 0) return false;
            let json = jsonStr[jsonStr.length - 1].geometry;
            if (json.type !== "Point") return false;
            let typeList = ["draw:created", "draw:editstop"]; // 创建、编辑、删除
            if (event.type === "draw:deleted") {
                this.basicForm.Jcwzdtxx.Zbxx = JsonStr;
            } else if (typeList.indexOf(event.type) > -1) {
                let res = this.isInPolygon(event.layer);
                if (res.length === 0) {
                    this.common.showMsg(
                        "请在新建项目范围内创建监测点！",
                        "error"
                    );
                    this.$refs.myMapDraw.deleteLayer(event.layer);
                } else {
                    this.basicForm.Jcwzdtxx.Zbxx = JsonStr;
                }
            }
        },
        // 判断包含该点的面
        isInPolygon(layer) {
            let latLng = layer.getLatLng();
            let data = [];
            this.areaLayers.eachLayer((area) => {
                let result = this.isPointInPolygon(area, latLng);
                if (result) {
                    data.push(area);
                }
            });
            return data;
        },
        // 判断点是否在面中-处理坐标
        isPointInPolygon(polygon, point) {
            let polygon1 = polygon.getLatLngs();
            let polygonData = [];
            polygon1[0].forEach((element) => {
                let a = { x: element.lat, y: element.lng };
                polygonData.push(a);
            });
            let pointData = { x: point.lat, y: point.lng };
            let res = this.insidePolygon(polygonData, pointData);
            return res;
        },
        // 替换司南超擎里面判断点在面中的API
        insidePolygon(points, testPoint) {
            let x = testPoint.x;
            let y = testPoint.y;
            let inside = false;
            for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
                let xi = points[i].x;
                let yi = points[i].y;
                let xj = points[j].x;
                let yj = points[j].y;

                let intersect =
                    yi > y !== yj > y &&
                    x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
                if (intersect) inside = !inside;
            }
            return inside;
        },
        // 避免重名监测点名称
        avoidDuplicateName(num, nameValue) {
            let nameList = [];
            this.configList.forEach((v) => {
                if (v && v.JcwzDto && v.JcwzDto.Jcwzmc) {
                    nameList.push(v.JcwzDto.Jcwzmc);
                }
            });
            let name = `${nameValue}${num}`;
            if (!nameList.includes(name)) {
                return name;
            } else {
                let ind = num + 1;
                return this.avoidDuplicateName(ind, nameValue);
            }
        },
        // 判断是否有重名监测点名称
        isHasDuplicateName() {
            let nameList = [];
            this.configList.forEach((v) => {
                if (!nameList.includes(v.JcwzDto.Jcwzmc)) {
                    nameList.push(v.JcwzDto.Jcwzmc);
                }
            });
            let noDuplicate =
                this.configList.length === nameList.length ? true : false;
            return noDuplicate;
        },
        // 过滤采集周期 只允许输入数字且第一位不能为0
        formatZq() {
            this.ruleForm.Zq = this.ruleForm.Zq.replace(/[^0-9]/g, "").replace(
                /^0*/g,
                ""
            );
        },
        closeDisesaseDialog() {
            this.$emit("closeDisesaseDialog");
        },
        getCjrItem(id) {
            this.ruleForm.Sjcjfzrid = id;
            this.$refs.SnTreeCjr.setLabelName(this.ruleForm.Sjcjfzrid);
        },
    },
};
</script>
<style lang="scss" scoped>
.menu-dialog {
    position: relative;
    .content-box {
        margin-top: 20px;
        .basic-data {
            .set-monitor {
                border-bottom: 1px dashed #bacbdf;
            }
        }
        .task-data {
            position: relative;
            .left-menu {
                width: 40px;
                height: calc(100% - 60px);
                position: absolute;
                top: 0;
                left: 0;
                ul {
                    position: relative;
                    .line {
                        width: 4px;
                        height: calc(100% + 50px);
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 6px;
                        background-color: #186d70; //#819fc1;
                    }
                    .task-item {
                        width: 30px;
                        height: 130px;
                        line-height: 30px;
                        text-align: center;
                        writing-mode: tb-rl;
                        color: #333335;
                        position: relative;
                        cursor: pointer;
                        &.active {
                            color: #fff;
                            // TODO 图片要替换
                            background: url("~@image/monitoring_cloud/leftNameBg.png");
                            .el-icon-close {
                                display: block;
                                position: absolute;
                                bottom: 10px;
                                right: 50%;
                                transform: translateX(50%);
                                font-weight: 700;
                                color: #fff;
                                cursor: pointer;
                            }
                        }
                        .el-icon-close {
                            display: none;
                        }
                    }
                    .add {
                        width: 28px;
                        margin-top: 15px;
                        color: #186d70;
                        font-size: 16px;
                        line-height: 50px;
                        text-align: center;
                        font-weight: 700;
                        border-top: 1px solid #d4ddec;
                        cursor: pointer;
                    }
                }
            }
            .right-content {
                width: 100%;
                // padding-left: 40px;

                .tip {
                    color: #ff9800;
                }
                .imgBox {
                    margin: 5px;
                    cursor: pointer;
                }
                .el-form-item {
                    margin-bottom: 15px;
                    .short-input {
                        // width: calc(100% - 200px);
                        width: 178px;
                    }
                }
                .select-item {
                    margin-bottom: 15px;
                }
                .lm-img {
                    display: block;
                    max-height: 300px;
                    max-width: 99%;
                    margin-bottom: 15px;
                }
                .footer {
                    border-top: 1px dashed #bacbdf;
                    text-align: right;
                    padding-top: 20px;
                }
                // .demo-ruleForm {
                .el-form-item {
                    margin-bottom: 15px;
                    .short-input {
                        // width: calc(100% - 200px);
                        width: 178px;
                    }
                    .el-radio-group {
                        // float: right;
                        margin-top: 0px;
                        margin-left: 8px;
                        .el-radio {
                            margin-right: 10px;
                            span.el-radio__label {
                                // TODO 不生效
                                padding-left: 2px !important;
                            }
                        }
                    }
                }
                .select-item {
                    margin-bottom: 15px;
                }
                .lm-img {
                    display: block;
                    max-height: 300px;
                    max-width: 99%;
                    margin-bottom: 15px;
                }
                .footer {
                    border-top: 1px dashed #bacbdf;
                    text-align: right;
                    padding-top: 20px;
                }
                // }
            }
        }
    }
}
.drawBox {
    height: 600px;
    position: relative;
    > div {
        height: 100%;
    }
}
</style>