<template>
    <sn-dialog :dialogData.sync="dialogData">
        <el-form
            ref="ruleForm"
            :model="ruleForm.data[0]"
            :rules="rules"
            label-width="110px"
            class="demo-ruleForm"
        >
            <el-form-item label="所属片区">
                {{ ruleForm.data[0].JcwzDto.ZBSJMC }}
            </el-form-item>
            <el-form-item
                v-if="ruleForm.data[0].JcwzDto.TCLX !== '306'"
                class="has-bottom-border"
                label="巡查点类型"
                prop="DWLX"
            >
                <el-radio-group
                    v-model="ruleForm.data[0].JcwzDto.DWLX"
                    class="radio-no-margin"
                    @change="changeXcdType"
                >
                    <el-radio :label="0">打卡点</el-radio>
                    <el-radio :label="1">采集点</el-radio>
                </el-radio-group>
            </el-form-item>
            <div
                v-if="[0, 1].indexOf(ruleForm.data[0].JcwzDto.DWLX) > -1"
                :class="btnItemShow ? 'has-bottom-border' : ''"
            >
                <el-form-item
                    v-if="
                        ycysState &&
                        ruleForm.data[0].JcwzDto.DWLX === 1 &&
                        ycysdtList.length > 0
                    "
                    label="遗产要素名称"
                    prop="YCYSMC"
                >
                    <el-select
                        v-model="ruleForm.data[0].JcwzDto.YCYSBM"
                        size="mini"
                        placeholder="遗产要素"
                        clearable
                        filterable
                        @change="changeJcwzmc"
                    >
                        <el-option
                            v-for="(item, index) in ycysdtList"
                            :key="index"
                            :label="item.MC"
                            :value="item.BM"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    :label="
                        ruleForm.data[0].JcwzDto.DWLX === 0
                            ? '打卡点名称'
                            : '采集点名称'
                    "
                    prop="JCWZMC"
                >
                    <p
                        v-if="
                            ruleForm.data[0].JcwzDto.DWLX === 1 &&
                            ruleForm.data[0].JcwzDto.YCYSBM
                        "
                    >
                        {{ ruleForm.data[0].JcwzDto.JCWZMC }}
                    </p>
                    <el-input
                        v-else
                        v-model="ruleForm.data[0].JcwzDto.JCWZMC"
                        size="mini"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    v-if="ycysState && ruleForm.data[0].JcwzDto.DWLX === 1"
                    label="要素本体"
                >
                    <el-switch
                        v-model="jcxs.btbczk"
                        :disabled="!ruleForm.data[0].JcwzDto.YCYSBM"
                        active-text="本体保存状况"
                        active-value="11"
                    ></el-switch>
                </el-form-item>
                <el-form-item
                    v-if="
                        ruleForm.data[0].JcwzDto.DWLX === 1 &&
                        ruleForm.data[0].JcwzDto.TCLX !== '306'
                    "
                    label="要素管理"
                >
                    <el-switch
                        v-model="jcxs.axfss"
                        active-text="安消防设施"
                        active-value="21"
                    ></el-switch>
                    <el-switch
                        v-model="jcxs.yqtt"
                        active-text="前端设备"
                        active-value="22"
                    ></el-switch>
                    <el-switch
                        v-model="jcxs.qt"
                        active-text="其他"
                        active-value="23"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="监测周期" prop="ZQ">
                    <el-input
                        v-model="ruleForm.data[0].JcpzDtos.ZQ"
                        size="mini"
                        class="short-input"
                        maxlength="5"
                        @input="formatZq"
                    ></el-input>
                    <el-radio-group v-model="ruleForm.data[0].JcpzDtos.ZQDW">
                        <el-radio :label="3">日</el-radio>
                        <el-radio :label="2">周</el-radio>
                        <el-radio :label="1">月</el-radio>
                        <el-radio :label="0">年</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="采集人" prop="SJCJFZRID">
                    <SnTreeCjr
                        @handleChange="getCjrItem"
                        :dialogDataState="dialogData.dialog"
                        ref="SnTreeCjr"
                    ></SnTreeCjr>
                </el-form-item>
                <!-- <el-form-item label-width="130px" label="是否立即下发任务" prop="RWSFBHDQZQ">
                    <el-radio-group v-model="ruleForm.data[0].JcpzDtos.RWSFBHDQZQ">
                        <el-radio label="1">是</el-radio>
                        <el-radio label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item> -->
            </div>
            <el-form-item v-if="btnItemShow" class="footer">
                <sn-button
                    type="primary"
                    :snButton.sync="snButton"
                    @handleChange="handleChange"
                ></sn-button>
            </el-form-item>
        </el-form>
    </sn-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "",
    props: {
        dialogData: {
            default() {
                return {};
            },
        },
        zoneId: {
            type: String,
            default() {
                return "";
            },
        },
        btnItemShow: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },
    data() {
        return {
            ycysState: false, //点击的是否是遗产要素
            jcxs: {
                btbczk: "",
                axfss: "21",
                yqtt: "22",
                qt: "23",
            },
            jcxEnum: {
                11: "btbczk",
                21: "axfss",
                22: "yqtt",
                23: "qt",
            },
            treeselectData: {
                clearable: true,
                searchable: true,
                placeholder: "专家类型",
                class: "", //selectRound
                width: "200px",
                list: [],
                optionLabel: "Name",
                optionValue: "Id",
                value: "Children",
                operateType: "treesearch",
                isShow: true,
            },
            ycysdtList: [],
            ruleForm: {},
            ruleBasicForm: {
                itemCode: "76",
                jcdxId: "",
                isCustomJcwz: 0,
                data: [
                    {
                        JcwzDto: {
                            TCLX: "", //图层类型
                            YCYSBM: "",
                            JCWZMC: "",
                            DWLX: null, // 0 打卡点， 1 采集点
                            ZBSJID: "", // 片区id
                            ZBSJMC: "",
                            JCDXBID: "9999",
                            JCWZDTXX: {
                                Zbxx: "",
                                Id: "",
                            },
                        },
                        JcpzDtos: {
                            CJSJ: "",
                            JCQSSJ: "",
                            ID: "",
                            JCPZFAMC: "",
                            ZBX: "",
                            LRFS: "2",
                            JCJSSJ: "",
                            SFSZQXJC: "1",
                            ZQ: "",
                            ZQDW: 3,
                            DS: "",
                            YJXQ: "",
                            BZ: "",
                            SJCJFZRID: "",
                            CJRID: "",
                            JCDXID: "",
                            RWSFBHDQZQ: "1",
                        },
                    },
                ],
            },
            rules: {
                // ZQ: [
                //     { required: true, message: '请输入采集周期', trigger: 'blur' },
                //     { min: 1, max: 5, message: '长度不能超过5', trigger: 'blur' }
                // ],
            },
            snButton: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "buttonBack",
                        name: "返回",
                        round: true,
                        backColor: "#FF9065",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "buttonSave",
                        name: "确定",
                        round: true,
                        backColor: "#2059CC",
                        color: "#fff",
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters(["system"]),
    },
    created() {
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleBasicForm));
    },
    mounted() {
        this.getYcysdt();
    },
    methods: {
        ...mapActions(["getYcysdtList", "commonSaveform", "getCorridorForm"]),
        async getDetail(item) {
            let _this = this;
            _this.ruleForm.data[0].JcpzDtos.ZQ = "";
            _this.ruleForm.data[0].JcpzDtos.SJCJFZRID = "";
            _this.ruleForm.data[0].JcwzDto.ID = "";
            _this.ruleForm.data[0].JcwzDto.JCWZDTXX.Id = "";
            _this.ruleForm.data[0].JcwzDto.TCLX = item.tclx;
            if (_this.$refs.SnTreeCjr) {
                _this.$refs.SnTreeCjr.setLabelName(null);
            }
            // "遗产要素","古茶树","古树名木","摄像头"
            // 未保存 处理其他类型的元素
            let list = ["7", "307", "306", "116"];
            if (item.tclx == "7") {
                _this.ycysState = true;
            } else {
                _this.ycysState = false;
            }
            if (list.includes(item.tclx)) {
                _this.ruleForm.data[0].JcwzDto.YCYSBM = item.ycysbm;
                _this.ruleForm.data[0].JcwzDto.ZBSJMC = item.pname;
                _this.ruleForm.data[0].JcwzDto.ZBSJID = item.ysjid; // 元数据id
                _this.ruleForm.data[0].JcwzDto.JCWZMC = `${item.label}打卡点`;
                if (item.tclx === "306") {
                    _this.ruleForm.data[0].JcwzDto.DWLX = 1;
                } else {
                    _this.ruleForm.data[0].JcwzDto.DWLX = 0;
                }
                _this.ruleForm.data[0].JcwzDto.ZBSJMC = item.label;
                _this.ruleForm.data[0].JcwzDto.JCWZDTXX.Zbxx = `{"type":"Point","coordinates":[${item.jd},${item.wd}]}`;
            } else {
                _this.ruleForm.data[0].JcwzDto.YCYSBM = "";
                _this.ruleForm.data[0].JcwzDto.ZBSJMC = item.label;
                _this.ruleForm.data[0].JcwzDto.JCWZMC = `${item.label}打卡点`;
                _this.ruleForm.data[0].JcwzDto.DWLX = 0;
                _this.ruleForm.data[0].JcwzDto.ZBSJID = item.pid; // 片区id
                _this.ruleForm.data[0].JcwzDto.JCWZDTXX.Zbxx = `{"type":"Point","coordinates":[${item.jd},${item.wd}]}`;
            }
            if (!item.id) {
                return;
            }

            // 遗产要素 古茶树 古树名木 摄像头 内置的第一次没有id
            let re = await _this.getCorridorForm({
                id: 76,
                jgslid: item.id,
            });
            let result = re && re[0];
            if (_this.$refs.SnTreeCjr) {
                if (result.SJCJFZRID) {
                    _this.$refs.SnTreeCjr.setLabelName(result.SJCJFZRID);
                } else {
                    _this.$refs.SnTreeCjr.setLabelName(null);
                }
            }

            if (!result) {
                _this.jcxs = {
                    // TODO 不优雅写法
                    btbczk: "",
                    axfss: "21",
                    yqtt: "22",
                    qt: "23",
                };
            }
            _this.setJcpzData(result);
            _this.setJcwzData(result);
            let zbx = result && result.ZBX;
            if (zbx) {
                Object.keys(_this.jcxEnum).forEach((key) => {
                    _this.jcxs[_this.jcxEnum[key]] = "";
                    if (zbx && zbx.indexOf(key) !== -1) {
                        _this.jcxs[_this.jcxEnum[key]] = key;
                    }
                });
            }
        },
        setJcpzData(result) {
            this.ruleForm.data[0].JcpzDtos.ID = (result && result.ID) || "";
            this.ruleForm.data[0].JcpzDtos.CJSJ = (result && result.CJSJ) || "";
            this.ruleForm.data[0].JcpzDtos.JCQSSJ =
                (result && result.JCQSSJ) || "";
            this.ruleForm.data[0].JcpzDtos.ZQ = (result && result.ZQ) || "";
            this.ruleForm.data[0].JcpzDtos.ZQDW = (result && result.ZQDW) || 3;
            this.ruleForm.data[0].JcpzDtos.SJCJFZRID =
                (result && result.SJCJFZRID) || "";
            this.ruleForm.data[0].JcpzDtos.RWSFBHDQZQ =
                (result && result.RWSFBHDQZQ) || "1";
        },
        setJcwzData(result) {
            let jcwzData = this.ruleForm.data[0].JcwzDto;
            jcwzData.YCYSBM = (result && result.YCYSBM) || "";
            jcwzData.JCWZMC = (result && result.JCDMC) || "";
            jcwzData.DWLX = result.DWLX;
            jcwzData.JCWZDTXX.Zbxx = result && result.ZBXX;
            jcwzData.JCWZDTXX.Id = result && result.ZBID;
            jcwzData.ZBSJID = (result && result.QYID) || "";
            jcwzData.ZBSJMC = (result && result.QYMC) || "";
            jcwzData.ID = (result && result.JCWZID) || ""; //监测位置
        },
        changeJcwzdtxx(value) {
            this.ruleForm = JSON.parse(JSON.stringify(this.ruleBasicForm));
            this.ruleForm.data[0].JcwzDto.JCWZDTXX.Zbxx = value;
        },
        initCjrData(id) {
            let cjrId = this.ruleForm.data[0].JcpzDtos.SJCJFZRID;
            if (!cjrId) {
                this.ruleForm.data[0].JcpzDtos.SJCJFZRID = id;
            }
        },
        changeJcdxbid(value, label) {
            this.ruleForm.data[0].JcwzDto.ZBSJID = value;
            this.ruleForm.data[0].JcwzDto.ZBSJMC = label;
            this.ruleForm.data[0].JcpzDtos.JCDXID = value;
        },
        // 巡查点类型
        changeXcdType(val) {
            if (
                !this.ruleForm.data[0].JcpzDtos.SJCJFZRID &&
                this.$refs.SnTreeCjr
            ) {
                this.$refs.SnTreeCjr.setLabelName(null);
            }
            if (!this.ruleForm.data[0].JcwzDto.JCWZMC.length) {
                return;
            }
            let dw = val == 0 ? "打卡点" : "采集点";
            this.ruleForm.data[0].JcwzDto.JCWZMC =
                this.ruleForm.data[0].JcwzDto.JCWZMC.slice(
                    0,
                    this.ruleForm.data[0].JcwzDto.JCWZMC.length - 3
                ) + dw;
        },
        changeJcwzmc(val) {
            // 修改遗产要素名称，同步修改采集点名称
            if (val) {
                let res = this.ycysdtList.filter((item) => {
                    return item.BM === val;
                });
                let dw =
                    this.ruleForm.data[0].JcwzDto.DWLX == 0
                        ? "打卡点"
                        : "采集点";
                this.ruleForm.data[0].JcwzDto.JCWZMC = res[0] && res[0].MC + dw;
            } else {
                this.jcxs.btbczk = "";
            }
        },
        async getYcysdt() {
            let result = await this.getYcysdtList({
                ycysfl2: this.ruleForm.data[0].JcwzDto.ZBSJMC,
            });
            this.ycysdtList = result || [];
        },
        async handleChange(type) {
            switch (type) {
                case "buttonSave":
                    this.saveData();
                    break;
                default:
                    this.dialogData.dialog = false;
                    break;
            }
        },
        async saveData() {
            if (!this.ruleForm.data[0].JcpzDtos.ZQ) {
                this.common.showMsg("填写周期", "warning");
                return;
            }
            if (!this.ruleForm.data[0].JcpzDtos.SJCJFZRID) {
                this.common.showMsg("选择采集人", "warning");
                return;
            }
            let valid = await this.$refs["ruleForm"].validate();
            let checkedJcx = [];
            Object.keys(this.jcxs).forEach((key) => {
                let jcx = this.jcxs[key];
                if (jcx) {
                    checkedJcx.push(jcx);
                }
            });
            if (valid) {
                if (checkedJcx.length > 0) {
                    this.ruleForm.data[0].JcpzDtos.ZBX = checkedJcx.join(",");
                } else {
                    this.ruleForm.data[0].JcpzDtos.ZBX = "";
                }

                let ruleForm = this.ruleForm.data[0].JcpzDtos;
                ruleForm.JCPZFAMC =
                    this.ruleForm.data[0].JcwzDto.JCWZMC + "监测任务配置";
                if (ruleForm.SJCJFZRID && ruleForm.ZQ) {
                    ruleForm.ZT = 1; // 启动任务
                } else {
                    ruleForm.ZT = 0;
                }

                // 判断是否“周”的周期ZQDW==“2”，判断是否周五、六、日，
                // 若是，RWSFBHDQZQ值为“0”，不立即下发任务，下个周期再下发
                if (+ruleForm.ZQDW === 2) {
                    let dayArr = [5, 6, 0];
                    if (dayArr.indexOf(new Date().getDay()) !== -1) {
                        ruleForm.RWSFBHDQZQ = 0;
                    }
                }

                this.ruleForm.jcdxId = this.ruleForm.data[0].JcwzDto.ZBSJID;
                this.ruleForm.data[0].JcwzDto.JCWZDTXX.Sjid =
                    this.ruleForm.data[0].JcwzDto.ID;
                let result = await this.commonSaveform(this.ruleForm);
                if (result.IsSuccess) {
                    this.common.showMsg("保存成功！", "success");
                    this.dialogData.dialog = false;
                    this.$emit("saveSuccess");
                } else {
                    this.common.showMsg("保存失败！", "success");
                }
            } else {
                this.common.showMsg("请检查是否填写完整！", "warning");
            }
        },
        getCjrItem(id) {
            this.ruleForm.data[0].JcpzDtos.SJCJFZRID = id;
        },
        formatZq() {
            this.ruleForm.data[0].JcpzDtos.ZQ =
                this.ruleForm.data[0].JcpzDtos.ZQ.replace(
                    /[^0-9]/g,
                    ""
                ).replace(/^0*/g, "");
        },
    },
};
</script>
<style lang="scss" scoped>
.demo-ruleForm {
    .el-form-item {
        margin-bottom: 15px;
        .short-input {
            // width: calc(100% - 200px);
            width: 178px;
        }
        .el-switch {
            width: 160px;
        }
        .el-switch.is-checked .el-switch__core {
            border-color: #2b64d7;
            background-color: #2b64d7;
        }
        .el-radio-group {
            // float: right;
            margin-top: 0px;
            margin-left: 15px;
            .el-radio {
                margin-right: 10px;
                span.el-radio__label {
                    // TODO 不生效
                    padding-left: 2px !important;
                }
            }
            &.radio-no-margin {
                margin-left: 0;
            }
        }
    }
    .has-bottom-border {
        border-bottom: 1px dashed #bacbdf;
    }
    .footer {
        // border-top: 1px dashed #BACBDF;
        text-align: right;
        padding-top: 20px;
    }
}
</style>
