<template>
    <div id="task-manage" class="task-manage">
        <!-- 表单 -->
        <sn-table-group
            v-if="tableShow"
            :tableGroupAttributes="commonData"
            :commonMethods="commonMethods"
            @handleChange="handleChange"
        ></sn-table-group>
        <!-- 查看详情弹出框 -->
        <dialogGather
            ref="dialogGather"
            :btnItemShow="true"
            @saveSuccess="saveSuccess"
        ></dialogGather>
        <sn-dialog :dialogData.sync="cjrDialogData">
            <el-form ref="ruleForm" label-width="80px" class="demo-ruleForm">
                <el-form-item label="采集人" prop="SJCJFZRID">
                    <SnTreeCjr
                        @handleChange="getCjrItem"
                        ref="SnTreeCjr"
                    ></SnTreeCjr>
                    <!-- <el-select
                        v-model="currentCjr"
                        filterable
                        size="mini"
                        placeholder="采集人"
                        clearable
                    >
                        <el-option
                            v-for="(item, index) in cjrList"
                            :key="index"
                            :label="item.REALNAME"
                            :value="item.ID"
                        ></el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item class="footer">
                    <sn-button
                        type="primary"
                        :snButton.sync="changeCjrSnButton"
                        @handleChange="handleChangeCjrDialog"
                    ></sn-button>
                </el-form-item>
            </el-form>
        </sn-dialog>
    </div>
</template>
<script>
import { commonData } from "./sn-table-group-common";
import { commonMethods } from "./table-common-methods";
import dialogGather from "./dialog_gather";
import { mapActions } from "vuex";
import mixins from "./task-list-mixins";
export default {
    name: "taskmanage",
    components: {
        dialogGather,
    },
    mixins: [mixins],
    props: {
        tableShow: {
            default() {
                return true;
            },
        },
        touristData: {
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            commonData,
            commonMethods,
            cjrDialogData: {
                title: "分配人员",
                dialog: false,
                class: "deepBlueDialog",
            },
            cjrList: [],
            typeid: "", // 当前查看/处理的是哪一个监测项
            pzid: "", // 当前查看/处理的是哪一条监测任务
            currentCjr: "", // 原先分配的采集人
            multipleSelection: [], // 选中项
            monitorOptions: [],
            changeCjrSnButton: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "buttonBack",
                        name: "返回",
                        round: true,
                        backColor: "#FF9065",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "buttonSave",
                        name: "确定",
                        round: true,
                        backColor: "#2059CC",
                        color: "#fff",
                    },
                ],
            },
            slhlyState: false, // 森林护林员 状态 存在 true 不存在 false
        };
    },
    created() {},
    mounted() {
        // let buttonData = this.commonData.snSearch.searchBtn.buttonData;
        // buttonData[buttonData.length - 1].operateType = 'buttonToMap';
        // buttonData[buttonData.length - 1].name = '地图视图';
        // buttonData[buttonData.length - 2].isShow = true;
    },
    methods: {
        ...mapActions([
            "updateJcpzZt",
            "updateCjr",
            "deleteJcpz",
            "getRwglList",
            "getCjrList",
            "CreatePeriodTask",
        ]),
        initSearch() {
            // 旅游游客中触发任务管理页面初始化筛选项
            this.keyword = "";
            this.rwlx = 1103;
            this.cjrid = "";
            this.rwzt = "";
            this.startTime = "";
            this.endTime = "";
            this.commonData.snPage.currentPage = 1;
            this.getTableData();
        },
        handleSelfChange(type, value) {
            console.log(
                "%c 🍼️ type, value: ",
                "font-size:20px;background-color: #4b4b4b;color:#fff;",
                type,
                value
            );
            switch (type) {
                case "dropdown": // 新增
                    this.toPage(value);
                    break;
                case "buttonToMap": // 地图视图
                    this.toPage([{ path: "taskManageMap" }]);
                    break;
                case "columnPause": // 暂停生成任务
                    this.changeRwzt(2, value[0]);
                    break;
                case "columnPlay": // 启动任务
                    this.changeRwzt(1, value[0]);
                    break;
                case "columnDelete": // 删除任务
                    this.deleteTask(value[0]);
                    break;
                case "columnToMap": // 去地图页面
                    this.toTaskMapPage(value[0]);
                    break;
                case "selection": // 选择左侧复选框
                    this.selectData(value[0]);
                    break;
                case "columnAllot": // 单个分配采集人
                    this.currentCjr = value[0].sjcjfzrid;
                    if (this.currentCjr) {
                        this.cjrDialogData.dialog = true;
                        this.$nextTick(() => {
                            this.$refs.SnTreeCjr.setLabelName(this.currentCjr);
                        });
                    }
                    // this.allotTask(value);
                    break;
                case "buttonAllot": // 批量分配采集人
                    this.allotSomeTask();
                    break;
                case "columnEnd": // 结束任务
                    this.changeRwzt(3, value[0]);
                    break;
                case "columnTmpTask":
                    this.handleTmpTask(value[0]);
                    break;
            }
        },
        async greatePeriodTask(val) {
            const result = await this.CreatePeriodTask({
                jcpzid: val.id,
            });
            if (result.issuccess) {
                this.$message({
                    type: "success",
                    message: "下发成功!",
                });
                this.getTableData();
            } else {
                this.$message({
                    type: "warning",
                    message: "下发失败!",
                });
            }
        },
        // 下发临时任务
        handleTmpTask(val) {
            console.log(45454545, val);
            this.$confirm("此操作将下发临时任务, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.greatePeriodTask(val);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消下发",
                    });
                });
        },
        // 点击选择框
        selectData(value) {
            this.multipleSelection = [];
            if (value && value.length > 0) {
                value.forEach((item) => {
                    this.multipleSelection.push({
                        id: item.id,
                        jcdxbid: item.jcx,
                    });
                });
            }
        },
        // 点击批量分配采集人的按钮
        allotSomeTask() {
            let checkboxlist = this.multipleSelection;
            if (checkboxlist.length === 0) {
                this.$message({
                    message: "请选择数据项！",
                    type: "warning",
                });
            } else {
                let pzidArr = [];
                checkboxlist.forEach((item) => {
                    pzidArr.push(item.id);
                });
                this.pzid = pzidArr.join();
                this.cjrDialogData.dialog = true;
                this.currentCjr = "";
                this.$nextTick(() => {
                    this.$refs.SnTreeCjr.setLabelName("");
                });
                // this.allotTask(checkboxlist);
            }
        },
        // 点击单个分配采集人的按钮
        async allotTask(val) {
            let typeidArr = [];
            let pzidArr = [];
            let funidArr = [];
            this.currentCjr = "";
            val.forEach((item) => {
                if (typeidArr.indexOf(item.jcx) === -1) {
                    typeidArr.push(item.jcx);
                    // TODO  要改成服务返回
                    if (item.jcx === "76") {
                        // 日常巡查
                        funidArr.push("f1b6f5b2-de6e-11e9-81a5-000c2977b7fd");
                    } else if (item.jcx === "602") {
                        // 遗产要素
                        funidArr.push("f20dd133-de6e-11e9-81a5-000c2977b7fd");
                    } else if (item.jcx === "70502") {
                        // 本体病害 TODO
                        funidArr.push("f1e6a84e-de6e-11e9-81a5-000c2977b7fd");
                    } else if (item.jcx === "1503") {
                        // 保护工程
                        funidArr.push("f18d6a83-de6e-11e9-81a5-000c2977b7fd");
                    } else if (item.jcx === "1103") {
                        // 日游客量
                        funidArr.push("a6792d52-e29a-11e9-81a5-000c2977b7fd");
                    } else if (item.jcx === "751") {
                        // 异常预警跟踪 TODO  是获取所有人吗？
                    } else if (item.jcx === "90203") {
                        // 新建项目
                        funidArr.push("e2c46679-9358-11ea-bd8d-000c2977b7fd");
                    } else if (item.jcx === "1403") {
                        // 考古发掘
                        funidArr.push("f9d46c6f-9358-11ea-bd8d-000c2977b7fd");
                    } else if (item.jcx === "90302") {
                        // 景观视线
                        funidArr.push("4896c21e-8a18-11eb-b3ce-000c2977b7fd");
                    } else if (item.jcx === "90304") {
                        // 景观风貌
                        funidArr.push("23368ed4-c275-11eb-b3ce-000c2977b7fd");
                    } else if (item.jcx === "1105") {
                        // 客流高峰
                        funidArr.push("2635dbad-8a18-11eb-b3ce-000c2977b7fd");
                    } else if (item.jcx === "500202") {
                        // 民族文化
                        funidArr.push("549f124a-d3f9-11eb-89af-000c2977b7fd");
                    } else if (item.jcx === "30801") {
                        // 民居考评
                        funidArr.push("3a959ec2-e485-11eb-89af-000c2977b7fd");
                    }
                }
                pzidArr.push(item.id);
            });
            this.typeid = typeidArr.join();
            this.pzid = pzidArr.join();
            if (val.length === 1) {
                this.currentCjr = val[0].sjcjfzrid;
            }
            this.cjrList = await this.getCjrList({
                funid: funidArr.join(),
            });
            this.cjrDialogData.dialog = true;
        },
        async handleChangeCjrDialog(type) {
            // 操作采集人弹框(保存、取消)
            if (type === "buttonSave") {
                let result = await this.updateCjr({
                    pzid: this.pzid,
                    cjrid: this.currentCjr,
                });
                if (result.issuccess) {
                    this.$message({
                        message: "分配采集人成功",
                        type: "success",
                    });
                    this.cjrDialogData.dialog = false;
                    this.getTableData();
                } else {
                    this.$message({
                        message: "分配采集人失败",
                        type: "error",
                    });
                }
            } else {
                this.cjrDialogData.dialog = false;
            }
        },
        async changeRwzt(type, val) {
            if (Number(type) === 3) {
                // 结束任务
                this.$confirm("是否结束任务?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            this.confirmChangeRwzt(type, val, done);
                        } else {
                            done();
                        }
                    },
                })
                    .then(() => {})
                    .catch(() => {
                        this.$message({
                            message: "已取消操作",
                            type: "info",
                        });
                    });
            } else {
                this.confirmChangeRwzt(type, val);
            }
        },
        async confirmChangeRwzt(type, val, done) {
            let da = await this.updateJcpzZt({
                pzid: val.id,
                zt: type,
            });
            if (da.issuccess) {
                if (done) {
                    done();
                }
                this.$message({
                    type: "success",
                    message: "操作成功",
                });
                this.getTableData();
            } else {
                this.$message({
                    type: "error",
                    message: "操作失败",
                });
            }
        },
        // 跳转到地图页面
        async toTaskMapPage(value) {
            let url = "";
            let params = "";
            this.monitorOptions.forEach((element, index) => {
                if (Number(element.value) === Number(value.jcx)) {
                    url = element.path;
                    params = value[element.paramsLabel];
                    return;
                }
            });
            this.$router.push({
                name: url,
                params: {
                    value: params,
                    type: "highLightCurrent",
                    data: {
                        jcdxid: value.jcdxid,
                    },
                },
                query: {
                    from: "taskManage",
                },
            });
        },
        deleteTask(value) {
            this.$confirm("是否永久删除该条记录?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                beforeClose: (action, instance, done) => {
                    if (action === "confirm") {
                        this.delData(value.id, done, instance);
                    } else {
                        done();
                    }
                },
            })
                .then(() => {})
                .catch(() => {
                    this.$message({
                        message: "已取消删除",
                        type: "info",
                    });
                });
        },
        // 确认删除
        async delData(id, done, instance) {
            let result = await this.deleteJcpz({
                jcpzid: id,
                isdeletejcwz: 1,
            });
            if (result.issuccess) {
                done();
                this.$message({
                    message: `${result.resultdescription}`,
                    type: "success",
                });
                this.commonData.snPage.currentPage = 1;
                this.getTableData();
            } else {
                this.$message({
                    message: `${result.resultdescription}`,
                    type: "error",
                });
            }
        },
        toPage(value) {
            this.$router.push({
                name: value[0].path,
            });
        },
        getCjrItem(id) {
            this.currentCjr = id;
            this.$refs.SnTreeCjr.setLabelName(this.currentCjr);
        },
    },
};
</script>
<style lang="scss" scoped>
.footer {
    border-top: 1px dashed #bacbdf;
    text-align: right;
    padding-top: 20px;
    &.el-form-item {
        margin-bottom: 5px;
    }
}
</style>
