const commonData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'id',
            treeProps: {
                children: 'children',
                hasChildren: 'hasChildren'
            },
        },
        columns: {
            // 选择、序号
            columnsOperate: [{
                isShow: true,
                type: 'selection',
                selectMethod: 'showAllotBtn',
                width: 35
            }, {
                isShow: true,
                label: '序号',
                type: 'index',
                width: 50
            }],
            // 数据列
            columnsData: [{
                prop: 'jcpzfamc',
                label: '任务名称',
                minWidth: 145
            }, {
                prop: 'zq',
                prop1: 'zqdw_bm',
                label: '采集周期',
                minWidth: 105
            }, {
                prop: 'zt',
                label: '采集状态',
                minWidth: 105,
                transList: {
                    0: '未开始',
                    1: '已开始',
                    2: '已暂停',
                    3: '已完成'
                },
                colorList: {
                    0: '#a0a0a0',
                    1: '#ff9066',
                    2: '#13ce67',
                    3: '#28ccd9'
                },
            }, {
                prop: 'sjcjfzrmc',
                label: '采集人',
                minWidth: 105
            }, {
                prop: 'jcqssj',
                label: '开始时间',
                minWidth: 165
            }, {
                prop: 'jcjssj',
                label: '结束时间',
                minWidth: 165
            }],
            // 操作列
            columnsBtn: {
                width: '200',
                buttonData: [{
                    isShow: true,
                    showMethod: 'showPauseBtn',
                    btnType: 'icon',
                    operateType: 'columnPause',
                    title: '暂停生成任务',
                    icon: 'fa fa-pause-circle-o one',
                    color: '#20a0ff'
                }, {
                    isShow: true,
                    showMethod: 'showPlayBtnByCJR',
                    btnType: 'icon',
                    operateType: '',
                    title: '请分配采集人再启动任务',
                    icon: 'fa fa-play-circle one',
                    color: '#20a0ff'
                }, {
                    isShow: true,
                    showMethod: 'showPlayBtn',
                    btnType: 'icon',
                    operateType: 'columnPlay',
                    title: '启动任务',
                    icon: 'fa fa-play-circle one',
                    color: '#20a0ff'
                }, {
                    isShow: true,
                    showMethod: 'showAllotBtn',
                    btnType: 'img',
                    operateType: 'columnAllot',
                    title: '分配任务',
                    src: 'monitoring_cloud/distribution.png',
                    class: 'two',
                }, {
                    isShow: true,
                    showMethod: 'showEndBtn',
                    btnType: 'icon',
                    operateType: 'columnEnd',
                    title: '结束任务',
                    icon: 'fa fa-lock three',
                    color: '#20a0ff'
                }, {
                    isShow: true,
                    // showMethod: '',
                    btnType: 'img',
                    operateType: 'columnInfo',
                    title: '查看详情',
                    src: 'monitoring_cloud/detail.png',
                    class: 'four',
                }, {
                    isShow: true,
                    showMethod: 'showDelBtn',
                    btnType: 'icon',
                    operateType: 'columnDelete',
                    title: '删除',
                    icon: 'fa fa-trash-o six',
                    backColor: 'transparent',
                    color: 'red'
                }]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    // snCount: {
    //     eachtotal: [10, 20, 50, 100],
    //     defaultTiaoshu: 10
    // },
};
export { commonData };