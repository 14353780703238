const commonMethods = {
    // 显示暂停的按钮
    showPauseBtn(row) {
        if (Number(row.zt) === 1) {
            return true;
        } else {
            return false;
        }
    },
    // 显示开始任务的按钮
    showPlayBtn(row) {
        if ((Number(row.zt) === 0 || Number(row.zt) === 2) && row.sjcjfzrid) {
            return true;
        } else {
            return false;
        }
    },
    // 显示开始任务按钮--有采集人才能开始
    showPlayBtnByCJR(row) {
        if (Number(row.zt) === 0 && !row.sjcjfzrid) {
            return true;
        } else {
            return false;
        }
    },
    // 显示结束任务按钮
    showEndBtn(row) {
        if (Number(row.zt) === 1 || Number(row.zt) === 2) {
            return true;
        } else {
            return false;
        }
    },
    // 显示分配任务按钮, 选择框可选择
    showAllotBtn(row) {
        if (Number(row.zt) !== 3) {
            return true;
        } else {
            return false;
        }
    },
    // 显示分配任务按钮, 选择框可选择
    showTmpTaskBtn(row) {
        if (row.lsrwzt === "1") {
            return true;
        } else {
            return false;
        }
    },
    // 显示删除按钮
    showDelBtn(row) {
        return true;
    },
    // 显示地图视图的按钮
    showMapBtn(row) {
        return row.jcx !== '1103'; // 旅游游客没有地图视图
    },
    // 显示版本变更的按钮
    // showRefreshBtn(row) {
    //     return Number(row.zt) !== 3;
    // },
    showColumnInfoBtn(row) {
        if (row.jcrwlx === '设备巡查') {
            return false;
        } else {
            return true;
        }
    }
};
export { commonMethods };