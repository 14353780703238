import { mapGetters, mapActions } from "vuex";
import jcxList from "./jcx_list.js";

export default {
    props: {},
    data() {
        return {
            jcxList: jcxList,
            keyword: '',
            rwlx: '',
            cjrid: '',
            rwzt: '',
            startTime: '',
            endTime: '',
        };
    },
    computed: {
        ...mapGetters(["system"]),
    },
    watch: {},
    created() { },
    methods: {
        ...mapActions([
            "GetDepartmentUserTree", // 获取采集人列表
        ]),
        handleChange(type, value) {
            console.log(type, value);
            switch (type) {
                case 'search': // 筛选条件
                    this[value[1]] = value[0];
                    this.commonData.snSearch.selectData.forEach(item => {
                        if (item.type === value[1]) {
                            item.value = value[0];
                        }
                    });
                    this.commonData.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'treesearch': // 点击查询按钮
                    if (value[1] === 'cjrid') {
                        // 采集人
                        this.cjrid = value[0];
                    }
                    this.commonData.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'total': // 每页条数
                    this[value[1]] = value[0];
                    this.commonData.snPage.tiaoshu = value[0];
                    this.commonData.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'page': // 当前是第几页
                    this.commonData.snPage.currentPage = value[0];
                    this.getTableData();
                    break;
                case 'columnInfo': // 查看详情
                    this.$refs.dialogGather.showDialog(value[0]);
                    break;
                default:
                    this.handleSelfChange(type, value);
                    break;
            }
        },
        async getTableData() {
            this.commonData.snTable.table.data = [];
            this.creatMapPoint = false;
            let page = this.commonData.snPage.tiaoshu;
            let excludelx = '';
            if (location.hash === '#/taskManageMap') {
                page = 10000;
            }
            if (location.hash === '#/taskExecution' && this.rwzt === '') {
                this.rwzt = '1,2,3';
            }


            if (location.hash === '#/taskManage') {
                excludelx = '999901';
            } else {
                excludelx = '';
            }

            // 森林护林员 跳转到 任务页面
            if (this.system == "system2") {
                let userId = this.$route.query.USERID;
                this.commonData.snSearch.selectData[1].list.map((item) => {
                    if (item.ID == userId) {
                        this.commonData.snSearch.selectData[1].default = userId;
                        this.cjrid = userId;
                    }
                });
                if (!this.cjrid) {
                    this.commonData.snSearch.selectData[1].list = []
                    return;
                }
            }

            let da = await this.getRwglList({
                keyword: this.keyword,
                rwlx: this.rwlx,
                cjrid: this.cjrid,
                rwzt: this.rwzt,
                startTime: this.startTime,
                endTime: this.endTime,
                pageIndex: this.commonData.snPage.currentPage,
                pageSize: page,
                excludelx: excludelx,

            });
            da.data.forEach(item => {
                if (!item.sfszqxjc || item.sfszqxjc === '0') {
                    item.zq = '无周期';
                    item.zqdw_bm = '';
                }
            })
            this.commonData.snTable.table.data = da.data;
            this.commonData.snPage.count = Number(da.total);
            this.commonData.loading = false;
            this.currentRow = da.data[0]; // 任务执行中用于高亮显示当前行
            this.creatMapPoint = true; // 任务管理列表页面开始创建地图上面的点或片区
        },
        // 获取所有采集人列表
        async getCjrData() {
            let da = await this.getCjrList({
                funid: '',
                depid: this.system == "system2" ? localStorage.departmentId : ''
            });
            let selectData = this.commonData.snSearch.selectData.filter(item => {
                return item.value === 'cjrid';
            });
            selectData[0].list = da.filter(item => { // TODO
                return item.REALNAME !== null;
            });
        },
        getJcxList() {
            let list = localStorage.jcxList;
            if (list) {
                let basicData = JSON.parse(localStorage.jcxList);
                if (this.system == "system2") {
                    let list = []
                    basicData.map((item) => {
                        if (item.value == "1503") {
                            item.label = "林业项目"
                            item.name = "林业项目"
                            list.push(item)
                        } else if (item.value == "76") {
                            list.push(item)
                        }
                    })
                    basicData = Array.from(list)
                }

                let monitorData = JSON.parse(JSON.stringify(basicData));
                monitorData.forEach(item => {
                    this.jcxList.map((item1) => {
                        if (item1.key == item.value) {
                            item.paramsLabel = item1.label
                        }
                    })
                });
                this.monitorOptions = monitorData;
                let selectData = this.commonData.snSearch.selectData[0];
                selectData.list = basicData; // 表格筛选

                let menuData = JSON.parse(JSON.stringify(basicData));
                menuData = menuData.filter(item => {
                    item.value = item.name;
                    return item.name !== '异常预警跟踪';
                }); // 不显示异常预警跟踪
                let searchBtnData = this.commonData.snSearch.searchBtn && this.commonData.snSearch.searchBtn.buttonData[0] || {};
                searchBtnData.menuList = menuData; // 新建下拉菜单
            } else {
                setTimeout(() => {
                    this.getJcxList();
                }, 10);
            }
        },
        saveSuccess() {
            this.getTableData();
        },
        handleChild(val) {
            if (val && val.length > 0) {
                val.forEach((ele) => {
                    ele.id = ele.Id;
                    ele.label = ele.Name;
                    if (ele.Children && ele.Children.length > 0) {
                        ele.children = ele.Children;
                        this.handleChild(ele.children);
                    }
                });
                return val;
            }
        },
        // 获取采集人列表
        async getDepartmentUserTree() {
            let result = await this.GetDepartmentUserTree();
            if (result && result.length > 0) {
                result.forEach((item) => {
                    item.id = item.Id;
                    item.label = item.Name;
                    if (item.Children && item.Children.length > 0) {
                        item.children = item.Children;
                        this.handleChild(item.children);
                    }
                });
                this.commonData.snSearch.treeselectData[0].list = result;
            }
        },
    },
    mounted() {
        let hash = location.hash;
        if (hash.indexOf('touristVisitors') === -1) { // 旅游游客引入了task-manage组件，但初始不调用这些服务
            this.getJcxList();
            // 获取采集人列表
            // this.getCjrData();
            this.getDepartmentUserTree();
            // 获取表格数据
            this.commonData.snPage.currentPage = 1;
            this.getTableData();


        } else {
            this.commonData = this.touristData;
        }
    }
};