<template>
    <sn-dialog :dialogData.sync="dialogData">
        <el-image
            class="sl-img"
            fit="cover"
            :src="basicData.Jgzp || ''"
            v-viewer
        ></el-image>
        <!-- <el-image class="sl-img" :src="down + basicData.Jgzp" v-viewer></el-image> -->
        <!-- <picRotate v-if="basicData.Jgzp" class="sl-img" :src="down + basicData.Jgzp" :previewSrcList="[down + basicData.Jgzp]"></picRotate> -->
        <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="80px"
            class="demo-ruleForm"
        >
            <el-form-item label="采集坐标">
                <span>{{ basicData.Zb }}</span>
            </el-form-item>
            <el-form-item label="采集位置">
                <span>{{ basicData.Fxms }}</span>
            </el-form-item>
            <el-form-item label="拍摄方向">
                <span>{{ getfx(basicData.Fx) }} {{ basicData.Fx }}°</span>
            </el-form-item>
            <el-form-item label="监测周期" prop="Zqdw">
                <el-input
                    v-model="ruleForm.Zq"
                    class="short-input"
                    size="mini"
                    maxlength="5"
                    @input="formatZq"
                ></el-input>
                <el-radio-group v-model="ruleForm.Zqdw">
                    <el-radio :label="3">日</el-radio>
                    <el-radio :label="2">周</el-radio>
                    <el-radio :label="1">月</el-radio>
                    <el-radio :label="0">年</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="采集人" prop="Sjcjfzrid">
                <!-- <el-select
                    v-model="ruleForm.Sjcjfzrid"
                    size="mini"
                    placeholder="采集人"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(item, index) in cjrList"
                        :key="index"
                        :label="item.REALNAME"
                        :value="item.ID"
                    ></el-option>
                </el-select> -->
                <SnTreeCjr
                    @handleChange="getCjrItem"
                    ref="SnTreeCjr"
                ></SnTreeCjr>
            </el-form-item>
            <el-form-item label="具体描述" prop="Bz">
                <el-input
                    v-model="ruleForm.Bz"
                    maxlength="500"
                    show-word-limit
                    type="textarea"
                    :rows="6"
                    size="mini"
                ></el-input>
            </el-form-item>
            <!-- <el-form-item label-width="130px" label="是否立即下发任务" prop="Rwsfbhdqzq">
                <el-radio-group v-model="ruleForm.Rwsfbhdqzq">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                </el-radio-group>
            </el-form-item> -->
            <el-form-item v-if="btnItemShow" class="footer">
                <sn-button
                    type="primary"
                    :snButton.sync="snButton"
                    @handleChange="handleChange"
                ></sn-button>
            </el-form-item>
        </el-form>
    </sn-dialog>
</template>
<script>
import { mapActions } from "vuex";
// import picRotate from "@comp/el_image_rotate";
export default {
    components: {
        // picRotate
    },
    props: {
        dialogData: {
            default() {
                return {};
            },
        },
        btnItemShow: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },
    data() {
        return {
            down: window.DOWN_URL,
            // 方位对象
            fxstate: {
                北: [337.5, 22.5],
                东北: [22.5, 67.5],
                东: [67.5, 112.5],
                东南: [112.5, 157.5],
                南: [157.5, 202.5],
                西南: [202.5, 247.5],
                西: [247.5, 292.5],
                西北: [292.5, 337.5],
            },
            basicForm: {}, // 配置的基本信息
            basicFormObj: {
                Id: "",
                Zbsjid: "",
                Jcwzmc: "",
                Jcwzsytmc: "",
                Jcwzsytlj: "",
                Jcwzms: "",
                Jcdxbid: "90301",
                Jcwzdtxx: {
                    Id: "",
                    Sjid: "",
                    Zbxx: "",
                },
                YDDJZTLJ: "",
                YDDJZTMC: "",
                SFLYJCDX: 1,
            },
            ruleBasicForm: {
                Id: "", // 监测配置的ID,
                Jcpzfamc: "", // 监测配置方案名称
                Zbx: "", // 指标项
                Lrfs: "2", // "录入方式", 移动端为2
                Jcqssj: "", // 监测起始时间
                Jcjssj: "", // 监测结束时间
                Sfszqxjc: "1", // 是否是周期性监测：0-否，1-是
                Zq: "", // 周期
                Zqdw: 3, // 周期单位
                Ds: "", // 定时
                Sc: "", // 时长
                Yjxq: "", // 预警需求
                Bz: "", // 监测描述，最大500字，
                Sjcjfzrid: "", // 数据采集负责人ID
                Rwsfbhdqzq: "1",
                SFLYJCDX: 1,
            },
            ruleForm: {},
            rules: {},
            cjrList: [],
            snButton: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "buttonBack",
                        name: "返回",
                        round: true,
                        backColor: "#FF9065",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "buttonSave",
                        name: "确定",
                        round: true,
                        backColor: "#2059CC",
                        color: "#fff",
                        disabled: true,
                    },
                ],
            },
            dateList: [],
            basicData: {
                Jgzp: "",
                Zb: "",
                Fxms: "",
                Fx: "",
            },
            sjid: "",
        };
    },
    mounted() {
        this.initUserList();
        this.getPicList();
    },
    methods: {
        ...mapActions([
            "getCjrList",
            "getCorridorForm",
            "getJbtuData",
            "commonSaveform",
        ]),
        getfx(value) {
            for (let key in this.fxstate) {
                if (
                    (value > this.fxstate[key][0] &&
                        value < this.fxstate[key][1]) ||
                    (key === "北" && value > this.fxstate[key][0]) ||
                    value < this.fxstate[key][1]
                ) {
                    return key;
                }
            }
        },
        handleClose() {},
        async saveData() {
            this.snButton.buttonData[1].disabled = true;
            this.ruleForm.Jcpzfamc = this.dialogData.title;
            if (this.ruleForm.Sjcjfzrid && this.ruleForm.Zq) {
                this.ruleForm.Zt = "1";
            }

            // 判断是否“周”的周期ZQDW==“2”，判断是否周五、六、日，
            // 若是，RWSFBHDQZQ值为“0”，不立即下发任务，下个周期再下发
            if (+this.ruleForm.Zqdw === 2) {
                let dayArr = [5, 6, 0];
                if (dayArr.indexOf(new Date().getDay()) !== -1) {
                    this.ruleForm.Rwsfbhdqzq = 0;
                }
            }

            let result = await this.commonSaveform({
                itemCode: "90302",
                jcdxId: this.sjid,
                isCustomJcwz: "0",
                data: [
                    {
                        JcwzDto: this.basicForm,
                        JcpzDtos: this.ruleForm,
                    },
                ],
            });
            if (result.IsSuccess) {
                this.$message({
                    type: "success",
                    message: "保存成功",
                });
                this.dialogData.dialog = false;
                this.$emit("saveSuccess");
            } else {
                this.$message({
                    message: "保存失败",
                    type: "error",
                });
            }
        },
        async handleChange(value) {
            if (value === "buttonSave") {
                if (!this.snButton.buttonData[1].disabled) {
                    this.saveData();
                }
            } else {
                this.dialogData.dialog = false;
            }
        },
        getDetail(value) {
            this.sjid = value;
            this.snButton.buttonData[1].disabled = false;
            this.getruleForm();
        },
        // 表单数据
        async getruleForm() {
            let result = await this.getCorridorForm({
                id: "90302",
                jgslid: this.sjid,
            });
            this.basicData = this.picList.filter(
                (item) => item.Id === this.sjid
            )[0];
            this.configList = (result &&
                result.jcwzAndJcpzDtos &&
                result.jcwzAndJcpzDtos.length > 0 &&
                result.jcwzAndJcpzDtos) || [{}];
            console.log(this.configList);
            if (this.configList[0] && this.configList[0].JcwzDto) {
                this.basicForm = this.configList[0].JcwzDto;
                if (!this.basicForm.Jcwzdtxx) {
                    this.basicForm.Jcwzdtxx = {
                        Id: "",
                        Sjid: "",
                        Zbxx: this.basicData.Zb,
                    };
                }
                if (this.basicForm.Jgzp) {
                    this.fileList = [
                        {
                            url: `${this.down}${this.basicForm.Jgzp}`,
                            name: "",
                        },
                    ];
                }
            } else {
                this.basicForm = this.getJcwzDto();
                this.configList[0].JcwzDto = this.basicForm;
            }
            if (
                result.jcwzAndJcpzDtos[0] &&
                result.jcwzAndJcpzDtos[0].JcpzDtos
            ) {
                this.ruleForm = result.jcwzAndJcpzDtos[0].JcpzDtos;
            } else {
                this.ruleForm = JSON.parse(JSON.stringify(this.ruleBasicForm));
                this.configList[0].JcpzDtos = this.ruleForm;
            }
            this.ruleForm.Bz = this.ruleForm.Bz || this.basicData.Jgxz;
            this.$refs.SnTreeCjr.setLabelName(this.ruleForm.Sjcjfzrid);
        },
        getJcwzDto() {
            console.log(this.basicData);
            let basicForm = JSON.parse(JSON.stringify(this.basicFormObj));
            let obj = {
                Zbsjid: this.basicData.Id,
                Jcwzsytlj: this.basicData.Jgzp,
                YDDJZTLJ: this.basicData.Jgzp,
                Jcwzms: this.basicData.Fxms,
                Jcwzdtxx: {
                    Id: "",
                    Sjid: "",
                    Zbxx: "",
                },
            };
            Object.keys(obj).forEach((key) => {
                basicForm[key] = obj[key];
            });
            return basicForm;
        },
        // 采集人
        async initUserList() {
            this.cjrList = await this.getCjrList({
                // funid: '804671f0-bf2f-11ea-bd8d-000c2977b7fd'
                funid: "4896c21e-8a18-11eb-b3ce-000c2977b7fd",
            });
        },
        // 获取图片列表
        async getPicList() {
            this.picList = await this.getJbtuData({
                id: "90301",
            });
        },
        // 过滤采集周期 只允许输入数字且第一位不能为0
        formatZq() {
            this.ruleForm.Zq = this.ruleForm.Zq.replace(/[^0-9]/g, "").replace(
                /^0*/g,
                ""
            );
        },
        getCjrItem(id) {
            this.ruleForm.Sjcjfzrid = id;
            this.$refs.SnTreeCjr.setLabelName(this.ruleForm.Sjcjfzrid);
        },
    },
};
</script>
<style lang="scss" scoped>
.title {
    height: 30px;
    color: #8c99a9;
}
.sl-img {
    display: block;
    height: 300px;
    text-align: center;
    margin-bottom: 15px;
}
.demo-ruleForm {
    .el-form-item {
        margin-bottom: 15px;
        .short-input {
            width: calc(100% - 200px);
        }
        .el-radio-group {
            // float: right;
            margin-top: 0px;
            margin-left: 8px;
            .el-radio {
                margin-right: 10px;
                span.el-radio__label {
                    // TODO 不生效
                    padding-left: 2px !important;
                }
            }
        }
    }
    .footer {
        border-top: 1px dashed #bacbdf;
        text-align: right;
        padding-top: 20px;
    }
}
</style>