<!-- map_draw 地图标绘组件-->
<template>
    <div class="map_img_draw">
        <!-- 地图主体 -->
        <div :id="'mapidbox' + mapid" class="map"></div>
        <!--  @updateDraw="updateDraw"-->
        <MapDraw
            v-if="drawAction"
            :basicMap="myMap"
            :drawConf="drawConf"
            :jsonStr.sync="newJson"
            @update:jsonStr="getJsonStr"
            @clickLayer="clickLayer"
        >
        </MapDraw>
    </div>
</template>

<script>
import MapDraw from "./MapDraw";
export default {
    name: "map_img_draw",
    components: {
        MapDraw,
    },
    props: {
        jsonlist: {
            type: String,
            default: () => {
                return "";
            },
        },
        imgUrl: {
            type: String,
            default: () => {
                return "";
            },
        },
        drawAction: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
        drawConf: {
            type: Object,
            default: () => {
                return {};
            },
        },
        zoomPosition: {
            type: String,
            default: () => {
                return "";
            },
        },
    },
    data() {
        return {
            mapid: Math.random(),
            myMap: null,
            newJson: "",
            imgDrawConf: {
                show: true,
            },
            imgLayer: null,
        };
    },
    computed: {},
    watch: {
        // newJson: function() {
        //     this.$emit("update:jsonlist", this.newJson)
        // },
        imgUrl: function() {
            this.initLayer();
            this.newJson = this.jsonlist;
            if (this.imgLayer) {
                this.myMap.removeLayer(this.imgLayer);
            }
        },
        jsonlist: function() {
            this.newJson = this.jsonlist;
        },
    },
    mounted() {
        this.initMap();
        this.newJson = this.jsonlist;
    },
    destroyed() {},
    methods: {
        initMap() {
            let id = `mapidbox${this.mapid}`;
            this.myMap = L.map(id, {
                crs: L.CRS.EPSG4326,
                zoomSnap: 0.1,
                editable: true,
                zoomControl: false,
                zoomDelta: 1,
                minZoom: 1,
                maxZoom: 17,
                attributionControl: false,
                center: L.latLng(40, 120),
            });
            this.$emit("update:myMapImg", this.myMap);
            if (this.zoomPosition) {
                window.L.control
                    .zoom({
                        position: this.zoomPosition,
                    })
                    .addTo(this.myMap);
            }
            if (this.imgUrl) {
                this.initLayer();
                this.newJson = this.jsonlist;
            }
            $.extend(this.imgDrawConf, this.drawConf);
        },
        initLayer() {
            // console.log('imgUrl', this.imgUrl);
            let map = this.myMap;
            let imageUrl = this.imgUrl;
            let img = new Image();
            img.src = this.imgUrl;
            img.onload = () => {
                // 基准点
                let imageBounds = [
                    [0, 0],
                    [1, img.width / img.height],
                ];

                // let imageBounds = [
                //     [-90, -180],
                //     [90, 180]
                // ]
                // eslint-disable-next-line
                this.imgLayer = L.imageOverlay(imageUrl, imageBounds);
                map.addLayer(this.imgLayer);
                map.fitBounds([imageBounds]);
                map.setMaxBounds(imageBounds);
            };
        },
        clickLayer(layer) {
            this.$emit("clickLayer", layer);
        },

        getJsonStr(JsonStr, event) {
            this.newJson = JsonStr;
            // this.$emit("update:jsonlist", this.newJson);
            this.$emit("update:jsonlist", JsonStr, event);
        },
    },
};
</script>
<style scoped>
.map_img_draw {
    width: 100%;
    height: 100%;
}
.warp_map_border {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    box-sizing: border-box;
    padding: 32px 2px 23px 2px;
    overflow: hidden;
    box-sizing: border-box;
}

.warp_map {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
}

.map_border {
    width: 100%;
    height: 100%;
    border-radius: 80px / 90px;
    position: relative;
    z-index: 1;
}

.map {
    width: 100%;
    height: 100%;
    z-index: 1;
}

/* 四周边框 */
.map_border_top {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.map_border_bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.map_border_left {
    width: 2px;
    height: calc(100% - 220px);
    position: absolute;
    left: 3px;
    top: 120px;
    z-index: 11;
}

.map_border_right {
    width: 2px;
    height: calc(100% - 220px);
    position: absolute;
    right: 3px;
    top: 120px;
    z-index: 11;
}
</style>
