// 监测项列表
let jcxList = [{
    name: "保护工程",
    key: "1503",
    label: "id",
    path: "protectionWorks"
}, {
    name: "异常预警跟踪",
    key: "751",
    label: "id",
    path: "abnormalWarningMap"
}, {
    name: "本体病害",
    key: "70502",
    label: "ycysbm",
    path: "ontologyDisease"
}, {
    name: "古茶树监测",
    key: "30701",
    label: "id",
    path: "ancientTeaTree"
}, {
    name: "客流高峰时段现场照片",
    key: "1105",
    label: "id",
    path: "commuterRushHour"
}, {
    name: "要素单体",
    key: "602",
    label: "ycysfl2",
    path: "heritageMonomer"
}, {
    name: "日常巡查",
    key: "76",
    label: "id",
    path: "dailyPatrol"
}, {
    name: "新建项目",
    key: "90203",
    label: "id",
    path: "newProject"
}, {
    name: "民居建设",
    key: "90502",
    label: "id",
    path: "monitorResidentialConstruct"
},
{
    name: "景观视线",
    key: "90302",
    label: "id",
    path: "viewingCorridor"
},
{
    name: "民族文化",
    key: "500202",
    label: "id",
    path: "ethnicCulture"
},
{
    name: "民居考评",
    key: "30801",
    label: "id",
    path: "residentialAssess"
},
    // 下面几项暂时没有
    // {
    //     name: "旅游游客",
    //     key: "1103",
    //     label: "id",
    //     path: "touristVisitors"
    // },
    // {
    //     name: "考古发掘",
    //     key: "1403",
    //     label: "id",
    //     path: "archaeologyDig"
    // },
    // {
    //     name: "景观风貌",
    //     key: "90304",
    //     label: "id",
    //     path: "landscapeStyle"
    // },
]

// label 点击 地图 按钮页面传值
export default jcxList